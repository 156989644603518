import React from "react";
import { PageHeader } from "antd";
import { Button, Divider, Form, Input, message, Select, Skeleton } from "antd";
import { Link, Route } from "react-router-dom";
// import MaskedInput from 'antd-mask-input'
import { callOnServer, RRServer } from "../../../utils/lib";
import {
  InstallmentInterface,
  StudentInterface,
  UserInterface,
} from "../../../types";
import { promises } from "fs";
import { CheckOutlined, CloseOutlined, UserOutlined } from "@ant-design/icons";
// import DrivingSchools from '../DrivingSchools/DrivingSchools';
import TextArea from "antd/lib/input/TextArea";
import { OrderedListOutlined, UserAddOutlined } from "@ant-design/icons";
import TopBar from "../../TopBar";
import FormComponent from "../../FormComponent/FormComponent";
// import EngagementAddCorporate from './EngagementAddCorporate';

// import EngagementList from './EngagementList';
import DrivingSchoolUserAdd from "./../User/subComponents/DrivingSchoolUserAdd";

interface DrivingSchoolInterface {
  code: string;
  id: string;
  drivingSchoolName: string;
  currentCount: string;
  sosNumber: string;
}
interface CorporateCodeInterface {
  password: string;
  id: string;
}

interface DrivingSchoolAddInterface {
  currentSchool: null | DrivingSchoolInterface;
  currentInstallment: null | InstallmentInterface;
  currentUser: null | UserInterface;
  schools: DrivingSchoolInterface[];
  recepients: StudentInterface[];
  recepientsSelected: any[];
  all: boolean;
  values: {
    drivingSchoolId: string;
    title: string;
    type: string;
    content: string;
    body:string;
    recepients: any[];
    condition: any[]
  };
}

const { Option } = Select;

class EngagementClone extends React.Component<any, DrivingSchoolAddInterface> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentSchool: null,
      currentInstallment: null,
      currentUser: null,
      schools: [],
      recepients: [],
      recepientsSelected: [],
      all: false,
      values:{
        drivingSchoolId: "",
        title: "",
        type: "PUSH_NOTIFICATION",
        content: "",
        body:"",
        recepients:[],
        condition: ['companyId','==']
      }
    };
    this.onFinish = this.onFinish.bind(this);
    // this.editInstallment = this.editInstallment.bind(this);
  }

  componentDidMount() {
    const claimsString = localStorage.getItem("claims") || "{}";
    const claims = JSON.parse(claimsString);
    claims.drivingSchoolId = claims.drivingSchoolId || "";

    const promises = [];
    if (claims.drivingSchoolId !== "*") {
      promises.push(
        RRServer.get({
          table: RRServer.Tables.DRIVING_SCHOOLS,
          id: claims.drivingSchoolId,
        })
      );
      promises.push(
        RRServer.list({
          table: RRServer.Tables.ENGAGEMENTS,
          forCurrentUser: false,
          where: [["drivingSchoolId", "==", claims.drivingSchoolId]],
        })
      );
    } else {
      promises.push(
        RRServer.list({
          table: RRServer.Tables.DRIVING_SCHOOLS,
          forCurrentUser: false,
        })
      );
      promises.push(
        RRServer.list({
          table: RRServer.Tables.ENGAGEMENTS,
          forCurrentUser: false,
        })
      );
    }

    Promise.all(promises).then((results: any) => {
      console.log(results);
      const schools = results[0].data.data;

      this.setState({ schools: schools.length ? schools : [schools] });
    });
  }

  onFinish = (values: any) => {

    console.log("valuess", this.state.values);
    const claimsString = localStorage.getItem('claims') || '{}';
    const claims = JSON.parse(claimsString);
    claims.drivingSchoolId = claims.drivingSchoolId || ''
    values.recepients = values.recepients[0] == 'all' ? this.state.recepients.map(r => r.id) : values.recepients;
    values.body=values.content;
    values.title='RoadRakshak';
    values.condition=[
        ['companyId','==',values.drivingSchoolId]
    ];
    RRServer.callFn('notification1-push', values)
  }

  onFinishFailed(errorInfo: any) {
    console.log("Failed:", errorInfo);
  }

  onSearch() {}

  getStudents = (drivingSchoolId: string) => {
    RRServer.list({
      table: RRServer.Tables.USERS,
      forCurrentUser: false,
      where: [
        ["companyId", "==", drivingSchoolId],
        ["type", "==", "DRIVING_SCHOOL_USER"],
      ],
    }).then((r: any) => this.setState({ recepients: r.data.data }));
  };
  toggleForm = (e: any) => {
    e.preventDefault();
    // this.setState({ isToggle: !this.state.isToggle });
    // console.log(this.state.isToggle);
  };

  handleValues = (values: any) => {
    console.log(values, "new values");
  };

  render() {
    // let { path, url } = this.props.match;
    const {
      currentSchool,
      currentInstallment,
      currentUser,
      schools,
      recepients,
      recepientsSelected,
    } = this.state;

    const schoolOption = schools.map((school) => (
      <Option key={school.id} value={school.id}>
        {school.drivingSchoolName}
      </Option>
    ));

    const arrayOption = schoolOption.map((skl, idx) => {
      console.log("log", skl.props);
    });

    // const fields: any = [
    //     { type: 'select', name: 'drivingSchoolId', label: 'Schools', options: [...schoolOption], isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
    //     { type: 'select', name: 'type', label: 'Type', options: [...arrayOption], isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
    //     { type: 'text', name: 'title', label: 'Title', isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
    //     { type: 'text', name: 'content', label: 'Content', isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
    // ]

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };
    const tailLayout = {
      wrapperCol: { offset: 8, span: 16 },
    };

    console.log(recepientsSelected, recepients);
    
    const claimsString = localStorage.getItem('claims') || '{}';
    const claims = JSON.parse(claimsString);
    const isLoggedIn = localStorage.getItem('claims');
    const isSuperAdmin = claims.drivingSchoolId === "*"? true : false
    const isClusterAdmin = claims.drivingSchoolId === "#" && claims?.userType === "CLUSTER"? true : false
    const isAssociationAdmin = claims.drivingSchoolId === "#" && claims?.userType === "ASSOCIATION"? true : false
    const isSkl =  claims.drivingSchoolId !== "*" && claims.drivingSchoolId !== "#"? true : false;
    return (
      <>
        <TopBar title="Engagement" />

        <div className="contentContainer" style={{width:"50%"}}>
          {/* <FormComponent fields={fields} toggleForm={(e: any) => this.toggleForm(e)} handleValues={this.handleValues} buttonText='Send' /> */}

         {!isSkl && <div className="form-floating" style={{margin:"10px"}}>
            <select
              className="form-select"
              id="floatingSelect1"
              aria-label="Floating label select example"
              name="drivingSchoolId"
              onChange={(e: any) => this.setState({ values:{...this.state.values, [e.target.name]:e.target.value,condition:[...this.state.values.condition, e.target.value] }  })}
            >
              <option selected>Schools</option>
              {schoolOption.map((skl, idx) => {
                return (
                  <option key={idx} value={skl.props.value}>
                    {skl.props.children}
                  </option>
                );
              })}
            </select>
            <label htmlFor="floatingSelect" >Schools</label>
          </div>}
          {isSkl && <div className="form-floating" style={{margin:"10px"}}>
            <select
              className="form-select"
              id="floatingSelect1"
              aria-label="Floating label select example"
              name="drivingSchoolId"
              onChange={(e: any) => this.setState({ values:{...this.state.values, [e.target.name]:e.target.value,condition:[...this.state.values.condition, e.target.value] }  })}
            >
              {schoolOption.map((skl, idx) => {
                return (
                  <option key={idx} value={skl.props.value} selected={idx === 0? true: false}>
                    {skl.props.children}
                  </option>
                );
              })}
            </select>
            <label htmlFor="floatingSelect" >Schools</label>
          </div>}

          <div className="form-floating" style={{margin:"10px"}}>
            <select
              className="form-select"
              id="floatingSelect2"
              name="type"
              onChange={(e: any) => this.setState({ values:{...this.state.values, [e.target.name]:e.target.value} })}
              aria-label="Floating label select example"
            >
              <option selected>Type</option>
              <option value="PUSH_NOTIFICATION">Push Notification</option>
    

              {/* {this.state.recepients.map(recepient => <option key={recepient.id} value={recepient.id} disabled={this.state.all }>{recepient.name}</option>)} */}

            </select>
            <label htmlFor="floatingSelect">Type</label>
          </div>

          <form className="form-floating" style={{margin:"10px"}}>
            <input
              type="text"
              className="form-control"
              id="floatingInputValue1"
              placeholder="name@example.com"
              onChange={(e: any) => this.setState({ values:{...this.state.values, [e.target.name]:e.target.value} })}
              name="title"
            />
            <label htmlFor="floatingInputValue">Title</label>
          </form>

          <form className="form-floating" style={{margin:"10px"}}>
            <input
              type="text"
              className="form-control"
              id="floatingInputValue2"
              placeholder="name@example.com"
              onChange={(e: any) => this.setState({ values:{...this.state.values, [e.target.name]:e.target.value , body: e.target.value} })}
              name="content"
            />
            <label htmlFor="floatingInputValue">Content</label>
          </form>

          <button className="btn btn-primary" style={{margin:"10px"}} onClick={this.onFinish}>
            {" "}
            submit
          </button>
        </div>
      </>
    );
  }
}

export default EngagementClone;
