import React from 'react';
import { Button, Divider, Form, Input, message, Typography, Steps, Modal, Table, Select, Card, Checkbox, DatePicker } from 'antd';
import { DrivingSchoolInterface, InstructorInterface, UserInterface, DAYS, DayType, StudentInterface, EMPTY_SLOTS, DateTimeType, DRIVING_SCHOOL_USER_TYPES, InstallmentInterface } from '../../../types';
// import Select, { OptionsType } from 'react-select'
// import commonStyles from '../../common/css/common.module.css';
import { formatTime, getClaims, RRServer } from '../../../utils/lib';
// import TableSelect from './sub-componets/TableSelect';
import dayjs from 'dayjs';

import firebase from '../../../utils/Firebase'

// import Styles from './DrivingSchoolUserAdd.module.css';
import moment from 'moment';
import TableSelect from './subComponents/TableSelect';
// import Installments from '../Installments/Installments';
import UserForm from './subComponents/UserForm';
import AddInstructorForm from './subComponents/AddInstructorForm';
import AddUserForm from './subComponents/AddUserForm';


const { Title } = Typography;
const { Step } = Steps;
const { Option } = Select;



interface DrivingSchoolUserAddProps {
    schools: DrivingSchoolInterface[],
    selectedSchool: DrivingSchoolInterface | null,
    selectedUserType: DRIVING_SCHOOL_USER_TYPES | null
    userCode: string,
    mode: "edit" | "add",
    currentStep: number,
    user: any,
    showSelectSchoolModal: boolean,
    showInstructorModal: boolean,
    instructors: InstructorInterface[],
    selectedInstructor: InstructorInterface | null,
    currentUser: UserInterface | null,
    numberOfClasses: number,
    numberOfInstallments: number,
    startDate: string,
    loading: boolean,
    installments: InstallmentLocalInterface[]
    drivingSchoolId: string
}

interface InstallmentCreateInterface {
    // numberOfInstallments: number
    uid: string
    companyId: string
}

export interface InstallmentLocalInterface {
    dueDate: string;
    id?: string
    uid?: string
    companyId?: string
    status: number
    paidOn: number
    installment: string,

}

class UserAdd extends React.Component<any, DrivingSchoolUserAddProps> {
    dsDropdown: React.RefObject<unknown>;
    CLASS_SCHEDULES: DateTimeType[] = [];
    unregisterAuthObserver: firebase.Unsubscribe | undefined;

    constructor(props: any) {
        super(props);
        this.state = {
            schools: [],
            selectedSchool: null,
            selectedUserType: null,
            userCode: '',
            mode:'add',
            currentStep: 0,
            user: null,
            showSelectSchoolModal: false,
            showInstructorModal: false,
            instructors: [],
            selectedInstructor: null,
            currentUser: null,
            numberOfClasses: 0,
            numberOfInstallments: 0,
            installments: [],
            startDate: '',
            loading: false,
            drivingSchoolId: ''
        }
        this.getSchool = this.getSchool.bind(this);
        this.onDrivingSchoolChange = this.onDrivingSchoolChange.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.dsDropdown = React.createRef();
    }

    componentDidMount() {

        this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
            () => {
                getClaims().then(claims => {

                    if (!claims || !!!claims['drivingSchoolId']) { console.log('No Claim'); return null }
                    const drivingSchoolId = claims ? claims['drivingSchoolId'] : '';

                    if (this.state.mode === 'edit') {

                        let reqs = [RRServer.get({ table: 'users', id: this.props.match.params.id })];
                        if (drivingSchoolId === '*') {
                            reqs.push(RRServer.list({ table: 'driving_schools', forCurrentUser: false }))
                        } else {
                            reqs.push(RRServer.get({ table: 'driving_schools', id: drivingSchoolId }))
                        }
                        Promise.all(reqs)
                            .then((results: any) => {
                                const currentUser = results[0].data.data;
                                const currentSchool = results[1].data.data.length
                                    ? results[1].data.data.find((school: DrivingSchoolInterface) => school.id === currentUser.companyId)
                                    : [results[1].data.data].find((school: DrivingSchoolInterface) => school.id === currentUser.companyId);
                                // debugger;
                                if (currentUser.type === 'DRIVING_SCHOOL_INSTRUCTOR') {
                                    currentUser.slots = currentUser.slots || EMPTY_SLOTS;
                                }

                                // console.log('AAA', currentUser)
                                this.setState({
                                    currentUser,
                                    user: currentUser,//@todo Dont know why we do this, we might as well use currentUser
                                    schools: [results[1].data.data],
                                    selectedSchool: currentSchool,
                                    startDate: currentUser.startDate,
                                    numberOfClasses: currentUser.numberOfClasses,
                                    selectedUserType: currentUser.type,
                                    drivingSchoolId
                                });
                                this.getInstructors(currentUser.companyId, currentUser.instructorId)
                            })
                    } else {


                        const promise = drivingSchoolId === "*"
                            ? RRServer.list({ table: 'driving_schools', forCurrentUser: false })
                            : RRServer.get({ table: 'driving_schools', id: drivingSchoolId })
                        promise
                            .then((result: any) => {

                                let schools = result.data.data.length ? result.data.data : [result.data.data];
                                this.setState({
                                    schools,

                                    //reset
                                    currentUser: null,
                                    user: null,//@todo Dont know why we do this, we might as well use currentUser
                                    selectedSchool: null,
                                    startDate: '',
                                    numberOfClasses: 0,
                                    selectedUserType: null
                                })
                            });

                    }

                });
            }
        );


    }

    componentWillUnmount() {
        this.unregisterAuthObserver!();
    }

    getSchool(schoolId: string) {
        const school = this.state.schools.filter(school => school.id = schoolId);
        return school[0];
    }

    getInstructors(companyId: string, instructorId?: string) {
        // RRServer.list({ table: 'instructors', where: [['companyId', '==', companyId]], forCurrentUser: false }).then((result: any) => {
        //     let _d: any = {
        //         instructors: result.data.data,
        //     };
        //     if (instructorId) {
        //         _d.selectedInstructor = result.data.data.find((ins: InstructorInterface) => ins.id === instructorId);
        //     }
        //     this.setState(_d)
        // });
        return RRServer.list({ table: 'users', where: [['companyId', '==', companyId], ['type', '==', 'DRIVING_SCHOOL_INSTRUCTOR']], forCurrentUser: false })
            .then((result: any) => {
                let _d: any = {
                    instructors: result.data.data,
                };
                if (instructorId) {
                    _d.selectedInstructor = result.data.data.find((ins: InstructorInterface) => ins.id === instructorId);
                }
                this.setState(_d)
            });
    }

    saveInstallments = (params: InstallmentCreateInterface) => {

        const { uid, companyId } = params;
        let insObjs = this.state.installments.map(ins => {
            return { ...ins, uid, companyId, dueDate: moment(ins.dueDate).format('DD-MM-YYYY') }
        });
        // for (let i = 0; i < params.numberOfInstallments; i++) {
        //     insObjs.push({
        //         installment: "Installment " + (i + 1),
        //         status: 0,
        //         paidOn: -1,
        //         uid,
        //         companyId
        //     })
        // }



        return RRServer.addMultiple({
            table: RRServer.Tables.INSTALLMENTS,
            data: insObjs
        })
            .then((r) => {
                console.log(r)
            })
    }

    simulateSaveInstallments = () => {

        this.saveInstallments({
            // numberOfInstallments: 4,
            uid: "axATfTonn9kiWXFNHcns",
            companyId: "3a9jkpr2LbU90jIYb9a5"
        })
    }



    addUserToSchool(user: UserInterface & { sendsms: boolean,typeOfVehicle:string,dob:string }) {
        const { name, mobile, companyId, code, password, type, sendsms,typeOfVehicle,dob } = user;
        this.setState({ loading: true })

        const { drivingSchoolId } = this.state;

        const link = 'https://play.google.com/store/apps/details?id=io.pyps.roadrakshak'

        RRServer.callFn('users1-add', {
            name: name,
            mobile: mobile,
            companyId: companyId,
            userCode: code,
            password,
            type,
            sendsms,
            typeOfVehicle,
            dob
        }).then((result: any) => {
            if (result.data.s) {
                // alert('Successfully Added User Driving School');
                message.success('Successfully Added User to Driving School');
                this.setState({
                    user: { ...user, id: result.data.id },
                    currentStep: 1,
                    loading: false
                });

                const { numberOfInstallments } = this.state;

                this.saveInstallments({ companyId, uid: result.data.id });

                this.getInstructors(user.companyId)
                // .then(()=>window.location.href = '/user')

                if (sendsms || drivingSchoolId !== '*') {
                    RRServer.callFn('notification-sms', {
                        tmpl: "sms-template-boarding",
                        phone: mobile,
                        code,
                        pass: password,
                        params: {
                            code,
                            link,
                            name,
                            password
                        }
                    })
                }
            }
        }).catch(function (error) {
            alert(error);
        })
    }



    onDrivingSchoolChange(value: string) {
        console.log('school', value)
        const school = this.getSchool(value);
        let ctr = school.currentCount;
        let newCtr = this.incrementCounter(ctr);
        let userCode = school.code.substring(0, 6) + newCtr;
        this.setState({
            selectedSchool: school,
            userCode
        });
    }


    getCodeBasedOnType = (selectedUserType: DRIVING_SCHOOL_USER_TYPES) => {

        const { selectedSchool } = this.state;
        if (selectedUserType && selectedSchool) {
            let ctr;

            if (selectedUserType === 'DRIVING_SCHOOL_USER') {
                ctr = selectedSchool!.currentCount;
            } else if (selectedUserType === 'DRIVING_SCHOOL_ADMIN') {
                ctr = selectedSchool!.currentCountAdmin;
            } else if (selectedUserType === 'DRIVING_SCHOOL_INSTRUCTOR') {
                ctr = selectedSchool!.instructorCurrentCount;
            } else if (selectedUserType === 'DRIVING_SCHOOL_OFFICE_ADMIN') {
                ctr = selectedSchool!.officeAdminCurrentCount;
            }
            return ctr;
        } else {
            return ''
        }

    }



    getCodeForSchool = (selectedUserType: DRIVING_SCHOOL_USER_TYPES) => {

        const { selectedSchool } = this.state;
        if (selectedUserType && selectedSchool) {
            let ctr, newCtr, userCode = '';

            if (selectedUserType === 'DRIVING_SCHOOL_ADMIN') {
                let t = selectedSchool!.currentCountAdmin.split('')
                let num = +t.slice(1).join('');
                if (num > 10) {
                    return false;
                }
            } else if (selectedUserType === 'DRIVING_SCHOOL_INSTRUCTOR') {
                let t = selectedSchool!.currentCountAdmin.split('')
                let num = +t.slice(1).join('');
                if (num > 99) {
                    return false;
                }
            } else if (selectedUserType === 'DRIVING_SCHOOL_OFFICE_ADMIN') {
                let t = selectedSchool!.currentCountAdmin.split('')
                let num = +t.slice(1).join('');
                if (num > 20) {
                    return false;
                }
            }

            ctr = this.getCodeBasedOnType(selectedUserType);
            newCtr = this.incrementCounter(ctr);
            userCode = selectedSchool!.code.substring(0, 6) + newCtr;

            return userCode;
        } else {
            return ''
        }

    }

    onSelectSchool = (school: DrivingSchoolInterface) => {
        let ctr = school.currentCount;
        this.setState({
            selectedSchool: school,
            // userCode
        });
    }
    onSelectInstructor = (instructor: InstructorInterface) => {
        this.setState({
            selectedInstructor: instructor,
        });
    }

    onFinish(values: any) {
        values.code = this.state.userCode;
        values.companyId = this.state.selectedSchool?.id;
        console.log('Finish', values)
        this.addUserToSchool(values);
    };

    onFinishFailed(errorInfo: any) {
        console.log('Failed:', errorInfo);
    };

    onSearch() { }

    onFormValueChange = (form: { [key: string]: any }) => {
        let key = Object.keys(form)[0]
        if (key === 'companyId') {
            this.onDrivingSchoolChange(form[key]);
        } else if (key === 'type') {
            const code = this.getCodeForSchool(form[key]);
            if (code === false) {
                alert('Max Limit of users reached');
                return;
            }
            this.setState({
                selectedUserType: form[key],
                userCode: code
            })
        }
    }

    incrementCounter(s: string) {
        let ss = s.split('');
        let alpha = ss.shift();
        let num = +ss.join('')
        num += 1;
        if (num > 99) {
            alpha = String.fromCharCode(alpha!.charCodeAt(0) + 1);
            num = 0;
        }
        const numStr = num < 10 ? ('0' + num) : num
        return alpha + '' + numStr;
    }

    nextStep = () => {
        this.setState({ currentStep: this.state.currentStep + 1 })
    }


    simulateSave = () => {
        // {"key":"buwdAf26G5MduywjTBnH","currentCount":"789","drivingSchoolName":"Q Edited","modifiedOn":1602353833445,"currentCountAdmin":"A02","profile":"We are the best dirving school in the city.","code":"123456A00","modifiedBy":"MRxrOnhO7YgA4HgKWZtSwCCdQJf1","id":"buwdAf26G5MduywjTBnH"}
        const user: UserInterface = { "type": "DRIVING_SCHOOL_USER", "name": "111", "mobile": "222", "password": "222", "code": "134567794", "companyId": "buwdAf26G5MduywjTBnH", "id": "szlyFWXY57EcqFbDUbfy", "instructorId": "" };
        this.setState({
            user: user,
            currentStep: 1
        });
        this.getInstructors(user.companyId)
    }


    showSelectSchoolModalToggle = () => {
        this.setState({
            showSelectSchoolModal: !this.state.showSelectSchoolModal
        })
    }
    showInstructorModalToggle = () => {
        this.setState({
            showInstructorModal: !this.state.showInstructorModal
        })
    }



    selectSlot = (day: DayType, slot: string) => {
        let user = this.state.user!;
        user.slots = user.slots || EMPTY_SLOTS;
        const f = user.slots[day].some((c:any) => c === slot);
        if (f) {
            user.slots[day] = user.slots[day].filter((c: any) => c !== slot);
        } else {
            user.slots[day].push(slot);
        }
        this.setState({ user })
    }

    saveSlotsForUser = () => {
        const { user, selectedInstructor, numberOfClasses, startDate, selectedSchool } = this.state;
        // if (!selectedSchool) {
        //     alert('Selected School Not Found')
        //     return;
        // }
        console.log('userr', user)
        const slots = user?.slots;
        console.log('slll',slots)
        let successMessage = 'Successfully Assigned Instructor to User';
        let objToUpdate = {}
        if (user?.type === "DRIVING_SCHOOL_USER") {
            objToUpdate = {
                slots,
                id: user?.id,
                instructorId: selectedInstructor?.id,
                classSchedules: this.CLASS_SCHEDULES,
                numberOfClasses,
                startDate
            }
        } else if (user?.type === 'DRIVING_SCHOOL_INSTRUCTOR') {
            objToUpdate = {
                slots,
            }
            successMessage = 'Successfully Saved Instructor Schedule'
        }


        let promises = [RRServer.update({
            table: RRServer.Tables.USERS,
            id: user?.id,
            ...objToUpdate
        })
        ];

        //Update the instructor with the students schedules
        if (user?.type === 'DRIVING_SCHOOL_USER') {
            let sch = selectedInstructor?.classSchedules || {};
            sch[user!.id] = this.CLASS_SCHEDULES;
            promises.push(RRServer.update({
                table: RRServer.Tables.USERS,
                id:  selectedInstructor!.id,
                classSchedules: sch
            }))
            RRServer.remove({
                table: RRServer.Tables.CLASS_SCHEDULES,
                where: [
                    ["studentId", "==", user!.id],
                    ["instructorId", "==", selectedInstructor!.id]
                ]
            })
        }

        this.setState({ loading: true })
        Promise.all(promises)
            .then(() => {
                message.success(successMessage);
                this.setState({ loading: false });
                //Add to class schedules table
                if (user?.type === 'DRIVING_SCHOOL_USER') {

                    RRServer.addMultiple({
                        table: RRServer.Tables.CLASS_SCHEDULES,
                        data: this.CLASS_SCHEDULES.map(sch => ({
                            studentId: user?.id,
                            student: user?.name,
                            instructorId: selectedInstructor!.id,
                            instructor: selectedInstructor!.name,
                            companyId: selectedSchool!.id,
                            school: selectedSchool!.drivingSchoolName,
                            date: sch.date,
                            time: sch.time
                        }))
                    })
                        .then(() => {
                            this.saveInstallments({ uid: user.id, companyId: selectedSchool!.id })
                                .then(() => window.location.reload())
                            console.log('submitt')
                        })
                } else {
                    console.log('submitted')
                    // window.location.reload()
                }
            })

    }

    skipForm = () => {
        this.nextStep();
    }

    changeNumberOfClasses = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({ numberOfClasses: Number(e.currentTarget.value) })
    }
    changeNumberOfInstallments = (e: React.FormEvent<HTMLInputElement>) => {

        let installments = []
        for (let i = 0; i < Number(e.currentTarget.value); i++) {
            installments.push({
                installment: "Installment " + (i + 1),
                status: 0,
                paidOn: -1,
                dueDate: dayjs().add(i, 'day').toISOString()//.format('DD-MM-YYYY')
            })
        }

        this.setState({ numberOfInstallments: Number(e.currentTarget.value), installments })
    }
    setDueDate = (insstallment: InstallmentLocalInterface, date: string) => {
        const installments = this.state.installments.map(ins => ins.installment == insstallment.installment ? { ...ins, dueDate: date } : ins);
        this.setState({ installments })
    }
    changeStartDate = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({ startDate: e.currentTarget.value })
    }

    // selectSlot = (day: DayType, time: string) => {
    //     let { user } = this.state;
    //     let slots = user!.slots || EMPTY_SLOTS;
    //     slots[day] = slots[day] || [];
    //     if (slots[day].some((t: string) => t === time)) {
    //         slots[day] = slots[day].filter((t: string) => t !== time);
    //     } else {
    //         slots[day].push(time);
    //     }
    //     user!.slots = slots;
    //     this.setState({ user });
    // }

    makeSuperUser = () => {
        const id = (document.getElementById('user-id') as HTMLInputElement)!.value;
        RRServer.callFn('setDSID', {
            uid: id,
            DSid: '*',
            admin: true
        })
            .then(r => {
                console.log(r)
            })
            .catch(err => {
                console.error(err)
            })

    }

    handleValues = (val:any) => {
        // val.code = this.state.userCode;
        // val.companyId = this.state.selectedSchool?.id;
        console.log('Finish', val)
        this.addUserToSchool(val);
    }

    handleInstructorSlots =async (val:any) => {
        console.log('ins slots',val)
        await this.setState({user:{...this.state.user, slots:{...val}}})
        this.saveSlotsForUser()
    }
    handleUserSlots = async (val:any) =>{
        console.log('valll',val)
        console.log('userr details',{user:{ ...this.state.user,slots:{...val.slots}},numberOfClasses:val.noOfClasses, startDate: val.startDate, selectedInstructor: this.state.instructors.filter(ins=> ins.id === val.instructorId)[0]})
        
        const instructor =this.state.instructors.filter(ins=> ins.id === val.instructorId)[0]
        const school = this.state.schools.filter(skl=>skl.id === this.state.user.companyId)[0]
        console.log('inss',instructor,'skl',school)

        await this.setState({user:{ ...this.state.user,slots:{...val.slots}},
            numberOfClasses:val.noOfClasses, 
            startDate: val.startDate,
            installments: val.installmentDates,
            selectedInstructor: instructor,
            selectedSchool:school})
 
        this.saveSlotsForUser()
    }

    render() {
        const { drivingSchoolId, installments, schools, selectedSchool, selectedUserType, instructors, selectedInstructor, userCode, currentStep, user, currentUser, mode, startDate, numberOfClasses, loading } = this.state;

        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || ''

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };
        const tailLayout = {
            wrapperCol: { offset: 8, span: 16 },
        };

        if (user) {
            user.slots = user.slots || EMPTY_SLOTS;
        }

        const columnsSchools = [
            {
                title: 'Name',
                dataIndex: 'drivingSchoolName',
                render: (text: React.ReactNode) => <span>{text}</span>,
            },
        ];
        const dataSchools = schools.map(school => ({
            key: school.id,
            ...school
        }))
        const columnsInstructors = [
            {
                title: 'Name',
                dataIndex: 'name',
                render: (text: React.ReactNode) => <span>{text}</span>,
            },
        ];
        const dataInstructors = instructors && instructors.map(school => ({
            key: school.id,
            ...school
        }))

        let slots = EMPTY_SLOTS;
        let len = Math.max(...DAYS.map((day: DayType) => slots[day].length));
        let trs = [];
        if (user?.type == 'DRIVING_SCHOOL_USER' && selectedInstructor) {
            slots = selectedInstructor.slots || EMPTY_SLOTS;
            len = selectedInstructor ? Math.max(...DAYS.map((day: DayType) => slots[day].length)) : 0;
            for (let i = 0; i < len; i++) {
                trs.push(DAYS.map((day: DayType) => {
                    const _slots = slots[day];
                    let cell = _slots[i] || '';
                    return <td key={day + '-' + i} className={user!.slots ? (user!.slots[day].some((c:any) => c === cell) ? 'selected' : '') : ''} onClick={() => this.selectSlot(day, cell)}>{cell}</td>;
                }));
            }
        }

        /**
         * @todo Move to another file
         */


        const fform = (props: any) => <UserForm handleValues={this.handleValues} schools={this.state.schools}/>


        const lis = [];
        if (startDate && selectedInstructor && user) {
            let _startDate = dayjs(startDate, "MM-DD-YYYY")
            const instructorSlots = selectedInstructor.slots || EMPTY_SLOTS;
            user.slots = user.slots || EMPTY_SLOTS;

            // console.log('Start Day', DAYS[_startDate.day()])
            // lis.push(<li><Divider /></li>)
            _startDate = dayjs(startDate, "MM-DD-YYYY")
            let classes = 0, iterations = 100;
            this.CLASS_SCHEDULES = [];
            do {
                iterations--;

                const day = DAYS[_startDate.day()];
                const instructorTimes = instructorSlots[day];
                const userTimes = user.slots[day];
                const doesUserWantClassOnDay = !!!userTimes?.length

                if (!instructorTimes.length || doesUserWantClassOnDay) {
                    // console.log(iterations)
                    _startDate = _startDate.add(1, 'd');
                    continue;
                } else {
                    classes += user.slots[day].length;
                }
                for (let j = 0; j < userTimes.length; j++) {
                    this.CLASS_SCHEDULES.push({ date: _startDate.format('DD/MM/YYYY'), time: userTimes[j] })
                    lis.push(<li key={'_' + j + classes}>
                        {_startDate.format('DD/MM/YYYY')}{' '}{userTimes[j]}
                    </li>);
                }

                _startDate = _startDate.add(1, 'd');

            } while (classes < numberOfClasses && iterations > 0)
        }


        const step2Title = selectedUserType ? (selectedUserType === 'DRIVING_SCHOOL_USER' ? 'Assign Instructor' : selectedUserType === 'DRIVING_SCHOOL_INSTRUCTOR' ? 'Instructor Schedule' : (selectedUserType === 'DRIVING_SCHOOL_ADMIN' ? 'Admin Step 2' : '')) : '';
        const START = 6;
        const SLOTS = 18;

        const getTimes = () => {
            let d = new Date(), times = [];
            for (let i = START; i < SLOTS; i++) {
                d.setHours(i);
                d.setMinutes(0);
                times.push(formatTime(d.toISOString()));
                d.setMinutes(30);
                times.push(formatTime(d.toISOString()));
            }
            return times;
        }

        let times = getTimes();

        return (
            <main>
                <Divider />

                {/* <button onClick={this.simulateSaveInstallments}>Save Installments</button> */}

                <Title>{mode === 'edit' ? 'Edit ' : 'Add '} Driving School User</Title>

                <Steps current={currentStep}>
                    <Step key={'Create User'} title={'Create User'} />
                    <Step key={step2Title} title={step2Title} />
                </Steps>


                <div >
                    <Divider />
{/* 
                    {claims.drivingSchoolId == "*" ?
                        <Card>
                            <input placeholder='user' id='user-id' /> <button onClick={this.makeSuperUser}>Add</button>
                        </Card>
                        : ""} */}
                    {currentStep === 0 && (currentUser && fform({ user: currentUser }))}
                    {currentStep === 0 && (!currentUser && fform({ user: null }))}


                    {/* Move to Another File and Import */}
                    {currentStep === 1 && user!.type == 'DRIVING_SCHOOL_USER' &&

                    <AddUserForm instructors={this.state.instructors} handleSlots={this.handleUserSlots}/>

                        // <div>
                        //     <Form
                        //         {...layout}
                        //         name="assignment"
                        //         // onValuesChange={this.onFormValueChange}
                        //         initialValues={{
                        //             ...user
                        //         }}

                        //     >
                        //         <Form.Item
                        //             label="Instructors"
                        //         >

                        //             <TableSelect
                        //                 title='Choose Instructor'
                        //                 onSelect={this.onSelectInstructor}
                        //                 columns={columnsInstructors}
                        //                 data={dataInstructors}
                        //                 selectedValue={selectedInstructor?.name}
                        //             />

                        //             <Button onClick={this.showInstructorModalToggle} type='link'> {selectedInstructor ? selectedInstructor.name : 'Select Instructor'} </Button>


                        //         </Form.Item>
                        //         <Form.Item
                        //             label="StartDate"
                        //             name="startDate"
                        //         >
                        //             <Input type="date" placeholder='Select Start Date' onChange={this.changeStartDate} />

                        //         </Form.Item>
                        //         <Form.Item
                        //             label="Number Of Classes"
                        //             name="numberOfClasses"
                        //         >
                        //             <Input type="number" placeholder='Number Of Classes' min={1} max={100} onChange={this.changeNumberOfClasses} />
                        //         </Form.Item>
                        //         <Form.Item
                        //             label="Number Of Installments"
                        //             name="numberOfInstallments"
                        //         >
                        //             <Input type="number" placeholder='Number Of Installments' min={1} max={100} onChange={this.changeNumberOfInstallments} />
                        //         </Form.Item>
                        //         {installments.map((ins, idx) => <Form.Item
                        //             label={ins.installment + ' Due Date'}
                        //             name={"installment" + idx}
                        //         >
                        //             <DatePicker defaultValue={moment(ins.dueDate)} format={'DD-MM-YYYY'} onChange={(e: any) => this.setDueDate(ins, e.toISOString())} />
                        //         </Form.Item>
                        //         )}

                        //         <Form.Item
                        //             label="Slots"
                        //         >

                        //             <table>
                        //                 <thead>
                        //                     <tr>
                        //                         {DAYS.map((day: DayType) => <th key={day}>{day}</th>)}
                        //                     </tr>
                        //                 </thead>
                        //                 <tbody>
                        //                     {trs.map((tr, idx) => <tr key={tr.length + '-' + idx}>{tr}</tr>)}
                        //                 </tbody>
                        //             </table>
                        //         </Form.Item>

                        //         <Form.Item
                        //             label="Class Schedule"
                        //         >
                        //             <ol>
                        //                 {lis}
                        //             </ol>

                        //         </Form.Item>


                        //     </Form>

                        //     <Form.Item {...tailLayout}>
                        //         {/* <Button type="primary" onClick={this.simulateSave}>
                        //             Simulate Save
                        //         </Button> */}
                        //         <Button type="primary" onClick={this.saveSlotsForUser}>
                        //             {mode === 'edit' ? 'Edit' : 'Submit'}
                        //         </Button>
                        //     </Form.Item>
                        // </div>

                    }

                    {/* Move to Anothe rFile and Import */}
                    {currentStep === 1 && user!.type == 'DRIVING_SCHOOL_INSTRUCTOR' &&
                    <AddInstructorForm handleSlots={this.handleInstructorSlots}/>
                        // <div>
                        //     <table>
                        //         <thead>
                        //             <tr>{DAYS.map((day:any) => <th key={day}>{day}</th>)}</tr>
                        //         </thead>
                        //         <tbody>
                        //             {times.map(time => <tr key={time}>{DAYS.map((day:any) => <td key={time + day} onClick={() => this.selectSlot(day, time)} className={(user!.slots![day].some((t:any) => t === time)) ? 'selectedSlot' : ''}>{time}</td>)}</tr>)}
                        //         </tbody>
                        //     </table>

                        //     <Button type="primary" onClick={this.saveSlotsForUser}>
                        //         {mode === 'edit' ? 'Edit' : 'Submit'}
                        //     </Button>
                        // </div>
                    }




                </div>

                <Modal
                    title="Choose School"
                    visible={this.state.showSelectSchoolModal}
                    onOk={this.showSelectSchoolModalToggle}
                    onCancel={this.showSelectSchoolModalToggle}
                >
                    <Table
                        rowSelection={{
                            type: 'radio',
                            onChange: (selectedRowKeys, selectedRows) => {
                                this.onSelectSchool(selectedRows[0])
                            },
                        }}
                        columns={columnsSchools}
                        dataSource={dataSchools}
                    />
                </Modal>

                <Modal
                    title="Choose Instructor"
                    visible={this.state.showInstructorModal}
                    onOk={this.showInstructorModalToggle}
                    onCancel={this.showInstructorModalToggle}
                >
                    <Table
                        rowSelection={{
                            type: 'radio',
                            onChange: (selectedRowKeys, selectedRows) => {
                                this.onSelectInstructor(selectedRows[0])
                            },
                        }}
                        columns={columnsInstructors}
                        dataSource={dataInstructors}
                    />
                </Modal>





            </main>
        )
    }
}

// export default withRouter(UserAdd);
export default UserAdd;