import React, { Component } from 'react';
import '../Sidebar/Sidebar.css'
import minLogo from '../../assets/SMILES.png'
import maxLogo from '../../assets/RRlogo.png';
import { ReactComponent as DashboardIcon } from '../../assets/svgs/Dashboard.svg'
import { ReactComponent as DashboardIconW } from '../../assets/svgs/Dashboard_W.svg'
import { ReactComponent as DrivingSchoolIcon } from '../../assets/svgs/DrivingSchool.svg'
import { ReactComponent as DrivingSchoolIconW } from '../../assets/svgs/DrivingSchool_W.svg'
import { ReactComponent as AssociationIcon } from '../../assets/svgs/Association.svg'
import { ReactComponent as AssociationIconW } from '../../assets/svgs/Association_w.svg'
import { ReactComponent as UsersIcon } from '../../assets/svgs/Users.svg'
import { ReactComponent as UsersIconW } from '../../assets/svgs/Users_W.svg'
import { ReactComponent as ClassesIcon } from '../../assets/svgs/Classes.svg'
import { ReactComponent as ClassesIconW } from '../../assets/svgs/Classes_W.svg'
import { ReactComponent as ScoresIcon } from '../../assets/svgs/scores.svg'
import { ReactComponent as ScoresIconW } from '../../assets/svgs/scores_W.svg'
import { ReactComponent as BBJourneyIcon } from '../../assets/svgs/BBJourney.svg'
import { ReactComponent as BBJourneyIconW } from '../../assets/svgs/BBJourney_W.svg'
import { ReactComponent as AssesmentIcon } from '../../assets/svgs/Assesment.svg'
import { ReactComponent as AssesmentIconW } from '../../assets/svgs/Assesment_W.svg'

import {ReactComponent as Dashboard_B} from '../../assets/bnw/Black/dashboard_B.svg'
import {ReactComponent as Dashboard} from '../../assets/bnw/White/dashboard.svg'
import {ReactComponent as DrivingSchool_B} from '../../assets/bnw/Black/drivingClasses_B.svg'
import {ReactComponent as DrivingSchool} from '../../assets/bnw/White/drivingClasses.svg'
import {ReactComponent as Association_B} from '../../assets/bnw/Black/Association_B.svg'
import {ReactComponent as Association} from '../../assets/bnw/White/Association.svg'
import {ReactComponent as Cluster_B} from '../../assets/bnw/Black/cluster_B.svg'
import {ReactComponent as Cluster} from '../../assets/bnw/White/cluster.svg'
import {ReactComponent as BB_B} from '../../assets/bnw/Black/BB_B.svg'
import {ReactComponent as BB} from '../../assets/bnw/White/BB.svg'

import {ReactComponent as CorporatePetroleum_B} from '../../assets/bnw/Black/corporatePetroleum_B.svg'
import {ReactComponent as CorporatePetroleum} from '../../assets/bnw/White/corporatePetroleum.svg'
import {ReactComponent as CorporateUser_B} from '../../assets/bnw/Black/corporateUser_B.svg'
import {ReactComponent as CorporateUser} from '../../assets/bnw/White/corporateUser.svg'
import {ReactComponent as Rescheduling_B} from '../../assets/bnw/Black/Rescheduling_B.svg'
import {ReactComponent as Rescheduling} from '../../assets/bnw/White/Rescheduling.svg'
import {ReactComponent as Installment_B} from '../../assets/bnw/Black/Installment_B.svg'
import {ReactComponent as Installment} from '../../assets/bnw/White/Installment.svg'
import {ReactComponent as StudentStats_B} from '../../assets/bnw/Black/StudentStat_B.svg'
import {ReactComponent as StudentStats} from '../../assets/bnw/White/StudentStat.svg'
import {ReactComponent as Engagement_B} from '../../assets/bnw/Black/Engagement_B.svg'
import {ReactComponent as Engagement} from '../../assets/bnw/White/Engagement.svg'
import {ReactComponent as Purchase_B} from '../../assets/bnw/Black/Purchases_B.svg'
import {ReactComponent as Purchase} from '../../assets/bnw/White/Purchases.svg'
import {ReactComponent as News_B} from '../../assets/bnw/Black/News_B.svg'
import {ReactComponent as News} from '../../assets/bnw/White/News.svg'


const schoolRoutes =  [
    {
        path: '/dashboard',
        label: 'Dashboard',
        icon: <Dashboard style={{width:"20", height:"20"}}/>,
        activeIcon: <Dashboard_B style={{width:"20", height:"20"}}/>
    },

 
        {
        path: '/usersOld',
        label: 'Users',
        icon: <UsersIcon style={{width:"20",height:"20"}}/>,
        activeIcon: <UsersIconW style={{width:"20",height:"20"}}/>
    },
    {
        path: '/classes',
        label: 'Classes',
        icon: <ClassesIcon style={{width:"20",height:"20"}}/>,
        activeIcon: <ClassesIconW style={{width:"20",height:"20"}}/>

    },
    {
        path: '/liveClasses',
        label: 'Live Classes',
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-clipboard-check-fill" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3Zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3ZM4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5v-1Zm6.854 7.354a.5.5 0 0 0-.708-.708L7.5 10.793 6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3Z" />
        </svg>,
        activeIcon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-clipboard-check-fill" viewBox="0 0 16 16">
        <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3Zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3Z"/>
        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5v-1Zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708Z"/>
      </svg>
    },
    {
        path: '/rescheduling',
        label: 'Rescheduling',
        icon: <Rescheduling style={{width:"20",height:"20"}}/>,
        activeIcon: <Rescheduling_B style={{width:"20",height:"20"}}/>
    },
    {
        path: '/installments',
        label: 'Installments',
        icon: <Installment style={{width:"20",height:"20"}}/>,
        activeIcon: <Installment_B style={{width:"20",height:"20"}}/>
    },
    {
        path: '/scores',
        label: 'Scores',
        icon: <ScoresIcon style={{width:"20",height:"20"}}/>,
        activeIcon: <ScoresIconW style={{width:"20",height:"20"}}/>
    },
    {
        path: '/studentStats',
        label: 'Student Stats',
        icon: <StudentStats style={{width:"20",height:"20"}}/>,
        activeIcon: <StudentStats_B style={{width:"20",height:"20"}}/>
    },
    
    {
        path: '/engagement',
        label: 'Engagement',
        icon: <Engagement style={{width:"20",height:"20"}}/>,
        activeIcon: <Engagement_B style={{width:"20",height:"20"}}/>
    },
    {
        path: '/purchase',
        label: 'Purchase',
        icon: <Purchase style={{width:"20",height:"20"}}/>,
        activeIcon: <Purchase_B style={{width:"20",height:"20"}}/>
    },
    {
        path: '/news',
        label: 'News',
        icon: <News style={{width:"20",height:"20"}}/>,
        activeIcon: <News_B style={{width:"20",height:"20"}}/>
    },
]

const superAdminRoutes = [
    {
        path: '/dashboard',
        label: 'Dashboard',
        icon: <Dashboard style={{width:"20", height:"20"}}/>,
        activeIcon: <Dashboard_B style={{width:"20", height:"20"}}/>
    },
    {
        path: '/drivingschools',
        label: 'Driving Schools',
        icon: <DrivingSchool style={{width:"20",height:"20"}}/>,
        activeIcon: <DrivingSchool_B style={{width:"20",height:"20"}}/>
    },
    {
        path: '/association',
        label: 'Association',
        icon: <Association style={{width:"20",height:"20"}}/>,
        activeIcon: <Association_B style={{width:"20",height:"20"}}/>
    },
    {
        path: '/cluster',
        label: 'Cluster',
        icon: <Cluster style={{width:"20",height:"20"}}/>,
        activeIcon: <Cluster_B style={{width:"20",height:"20"}}/>
    },
        {
        path: '/usersOld',
        label: 'Users',
        icon: <UsersIcon style={{width:"20",height:"20"}}/>,
        activeIcon: <UsersIconW style={{width:"20",height:"20"}}/>
    },
    {
        path: '/classes',
        label: 'Classes',
        icon: <ClassesIcon style={{width:"20",height:"20"}}/>,
        activeIcon: <ClassesIconW style={{width:"20",height:"20"}}/>

    },
    {
        path: '/liveClasses',
        label: 'Live Classes',
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-clipboard-check-fill" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3Zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3ZM4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5v-1Zm6.854 7.354a.5.5 0 0 0-.708-.708L7.5 10.793 6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3Z" />
        </svg>,
        activeIcon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-clipboard-check-fill" viewBox="0 0 16 16">
        <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3Zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3Z"/>
        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5v-1Zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708Z"/>
      </svg>
    },
    {
        path: '/rescheduling',
        label: 'Rescheduling',
        icon: <Rescheduling style={{width:"20",height:"20"}}/>,
        activeIcon: <Rescheduling_B style={{width:"20",height:"20"}}/>
    },
    {
        path: '/installments',
        label: 'Installments',
        icon: <Installment style={{width:"20",height:"20"}}/>,
        activeIcon: <Installment_B style={{width:"20",height:"20"}}/>
    },
    {
        path: '/scores',
        label: 'Scores',
        icon: <ScoresIcon style={{width:"20",height:"20"}}/>,
        activeIcon: <ScoresIconW style={{width:"20",height:"20"}}/>
    },
    {
        path: '/studentStats',
        label: 'Student Stats',
        icon: <StudentStats style={{width:"20",height:"20"}}/>,
        activeIcon: <StudentStats_B style={{width:"20",height:"20"}}/>
    },
    {
        path: '/bbjourney',
        label: 'BB Journey',
        icon: <BB style={{width:"20",height:"20"}}/>,
        activeIcon: <BB_B style={{width:"20",height:"20"}}/>
    },
    {
        path: '/assessments',
        label: 'Assessments',
        icon: <AssesmentIcon style={{width:"20",height:"20"}}/>,
        activeIcon: <AssesmentIconW style={{width:"20",height:"20"}}/>
    },
    {
        path: '/engagement',
        label: 'Engagement',
        icon: <Engagement style={{width:"20",height:"20"}}/>,
        activeIcon: <Engagement_B style={{width:"20",height:"20"}}/>
    },
    {
        path: '/purchase',
        label: 'Purchase',
        icon: <Purchase style={{width:"20",height:"20"}}/>,
        activeIcon: <Purchase_B style={{width:"20",height:"20"}}/>
    },
    {
        path: '/news',
        label: 'News',
        icon: <News style={{width:"20",height:"20"}}/>,
        activeIcon: <News_B style={{width:"20",height:"20"}}/>
    },
    {
        path: '/corporate',
        label: 'Corporate',
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-gear-fill" viewBox="0 0 16 16">
            <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
        </svg>
    },
    {
        path: '/corporateUser',
        label: 'Corporate User',
        icon: <CorporateUser style={{width:"20",height:"20"}}/>,
        activeIcon: <CorporateUser_B style={{width:"20",height:"20"}}/>
    },
    {
        path: '/corporatePetroleum',
        label: 'Corporate Petroleum',
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-gear-fill" viewBox="0 0 16 16">
            <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
        </svg>
    },
    {
        path: '/corporatePetroleumUser',
        label: 'Corporate Petroleum User',
        icon: <CorporatePetroleum style={{width:"20",height:"20"}}/>,
        activeIcon: <CorporatePetroleum_B style={{width:"20",height:"20"}}/>
    },
    {
        path: '/settings',
        label: 'Settings',
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-gear-fill" viewBox="0 0 16 16">
            <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
        </svg>
    },
    {
        path: '/users',
        label: 'Users New',
        icon: <UsersIcon style={{width:"20",height:"20"}}/>,
        activeIcon: <UsersIconW style={{width:"20",height:"20"}}/>
    },
]

const clusterAdminRoutes = [
    {
        path: '/dashboard',
        label: 'Dashboard',
        icon: <Dashboard style={{width:"20", height:"20"}}/>,
        activeIcon: <Dashboard_B style={{width:"20", height:"20"}}/>
    },
    {
        path: '/drivingschools',
        label: 'Driving Schools',
        icon: <DrivingSchool style={{width:"20",height:"20"}}/>,
        activeIcon: <DrivingSchool_B style={{width:"20",height:"20"}}/>
    },
        {
        path: '/usersOld',
        label: 'Users',
        icon: <UsersIcon style={{width:"20",height:"20"}}/>,
        activeIcon: <UsersIconW style={{width:"20",height:"20"}}/>
    },
    {
        path: '/classes',
        label: 'Classes',
        icon: <ClassesIcon style={{width:"20",height:"20"}}/>,
        activeIcon: <ClassesIconW style={{width:"20",height:"20"}}/>

    },
    {
        path: '/liveClasses',
        label: 'Live Classes',
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-clipboard-check-fill" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3Zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3ZM4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5v-1Zm6.854 7.354a.5.5 0 0 0-.708-.708L7.5 10.793 6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3Z" />
        </svg>,
        activeIcon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-clipboard-check-fill" viewBox="0 0 16 16">
        <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3Zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3Z"/>
        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5v-1Zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708Z"/>
      </svg>
    },
    {
        path: '/rescheduling',
        label: 'Rescheduling',
        icon: <Rescheduling style={{width:"20",height:"20"}}/>,
        activeIcon: <Rescheduling_B style={{width:"20",height:"20"}}/>
    },
    {
        path: '/installments',
        label: 'Installments',
        icon: <Installment style={{width:"20",height:"20"}}/>,
        activeIcon: <Installment_B style={{width:"20",height:"20"}}/>
    },
    {
        path: '/scores',
        label: 'Scores',
        icon: <ScoresIcon style={{width:"20",height:"20"}}/>,
        activeIcon: <ScoresIconW style={{width:"20",height:"20"}}/>
    },
    {
        path: '/studentStats',
        label: 'Student Stats',
        icon: <StudentStats style={{width:"20",height:"20"}}/>,
        activeIcon: <StudentStats_B style={{width:"20",height:"20"}}/>
    },
    {
        path: '/engagement',
        label: 'Engagement',
        icon: <Engagement style={{width:"20",height:"20"}}/>,
        activeIcon: <Engagement_B style={{width:"20",height:"20"}}/>
    },
    {
        path: '/settings',
        label: 'Settings',
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-gear-fill" viewBox="0 0 16 16">
            <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
        </svg>
    },
]

const associationAdminRoutes = [
    {
        path: '/dashboard',
        label: 'Dashboard',
        icon: <Dashboard style={{width:"20", height:"20"}}/>,
        activeIcon: <Dashboard_B style={{width:"20", height:"20"}}/>
    },
    {
        path: '/drivingschools',
        label: 'Driving Schools',
        icon: <DrivingSchool style={{width:"20",height:"20"}}/>,
        activeIcon: <DrivingSchool_B style={{width:"20",height:"20"}}/>
    },
        {
        path: '/usersOld',
        label: 'Users',
        icon: <UsersIcon style={{width:"20",height:"20"}}/>,
        activeIcon: <UsersIconW style={{width:"20",height:"20"}}/>
    },
 
    {
        path: '/classes',
        label: 'Classes',
        icon: <ClassesIcon style={{width:"20",height:"20"}}/>,
        activeIcon: <ClassesIconW style={{width:"20",height:"20"}}/>

    },
    {
        path: '/liveClasses',
        label: 'Live Classes',
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-clipboard-check-fill" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3Zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3ZM4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5v-1Zm6.854 7.354a.5.5 0 0 0-.708-.708L7.5 10.793 6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3Z" />
        </svg>,
        activeIcon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-clipboard-check-fill" viewBox="0 0 16 16">
        <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3Zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3Z"/>
        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5v-1Zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708Z"/>
      </svg>
    },
    {
        path: '/rescheduling',
        label: 'Rescheduling',
        icon: <Rescheduling style={{width:"20",height:"20"}}/>,
        activeIcon: <Rescheduling_B style={{width:"20",height:"20"}}/>
    },
    {
        path: '/scores',
        label: 'Scores',
        icon: <ScoresIcon style={{width:"20",height:"20"}}/>,
        activeIcon: <ScoresIconW style={{width:"20",height:"20"}}/>
    },
    {
        path: '/studentStats',
        label: 'Student Stats',
        icon: <StudentStats style={{width:"20",height:"20"}}/>,
        activeIcon: <StudentStats_B style={{width:"20",height:"20"}}/>
    },
    {
        path: '/engagement',
        label: 'Engagement',
        icon: <Engagement style={{width:"20",height:"20"}}/>,
        activeIcon: <Engagement_B style={{width:"20",height:"20"}}/>
    },
    {
        path: '/news',
        label: 'News',
        icon: <News style={{width:"20",height:"20"}}/>,
        activeIcon: <News_B style={{width:"20",height:"20"}}/>
    },
    {
        path: '/settings',
        label: 'Settings',
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-gear-fill" viewBox="0 0 16 16">
            <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
        </svg>
    }
]

class SideBar extends Component {



    handleClick = (e: any) => {
        console.log('parent', e)
        console.log('event', e.target.tagName)
        // if (!e.target.classList.contains('active')) {
        //     return e.target.classList.add('active')
        // }
        const menusEl = document.querySelectorAll('.side-bar ul li')
        // console.log('menuu', menusEl)
        Array.from(menusEl).map(menu => {
            if (menu.classList.contains('active')) {
                menu.classList.remove('active')
            }
        })

        if (e.target.tagName === 'LI') {
            e.target.classList.add('active')
        } else if (e.target.tagName === 'SPAN') {
            e.target.parentElement.parentElement.classList.add('active')
        } else if (e.target.tagName === 'path') {
            e.target.parentElement.parentElement.parentElement.parentElement.classList.add('active')
        }

    }

    render() {
        const currentUrl = window.location.href

        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        const isLoggedIn = localStorage.getItem('claims');
        const isSuperAdmin = claims.drivingSchoolId === "*"? true : false
        const isClusterAdmin = claims.drivingSchoolId === "#" && claims?.userType === "CLUSTER"? true : false
        const isAssociationAdmin = claims.drivingSchoolId === "#" && claims?.userType === "ASSOCIATION"? true : false
        const isSkl =  claims.drivingSchoolId !== "*" && claims.drivingSchoolId !== "#"? true : false;


        return (
            <>
                <aside className="side-bar-wrap">
                    {console.log('url', currentUrl)}
                    <nav className="side-bar">
                        <div className="logo-area" style={{left:"-10px",padding:"30px",margin:"5px",borderRadius:"10px"}}>
                            <img className="min" src={minLogo} alt="min-logo" style={{width:"52px",height:"51px"}}/>
                            <img className="max" src={maxLogo} alt="max-logo" />
                        </div>
                        <ul>
                            {isSuperAdmin &&
                                superAdminRoutes.map((route) => {
                                    return <li className={`${currentUrl.endsWith(route.path) ? 'active' : ''}`} onClick={(e) => this.handleClick(e)} >
                                        <b></b>
                                        <b></b>
                                        <a href={route.path} onClick={(e) => this.handleClick(e)}>
                                            <span className="icon">
                                                {!currentUrl.endsWith(route.path) ? route.icon : route.activeIcon}
                                            </span>
                                            <span className="title" onClick={(e) => this.handleClick(e)} >{route.label}</span>
                                        </a>
                                    </li>
                                })
                            }
                                {isClusterAdmin &&
                                clusterAdminRoutes.map((route) => {
                                    return <li className={`${currentUrl.endsWith(route.path) ? 'active' : ''}`} onClick={(e) => this.handleClick(e)} >
                                        <b></b>
                                        <b></b>
                                        <a href={route.path} onClick={(e) => this.handleClick(e)}>
                                            <span className="icon">
                                                {!currentUrl.endsWith(route.path) ? route.icon : route.activeIcon}
                                            </span>
                                            <span className="title" onClick={(e) => this.handleClick(e)} >{route.label}</span>
                                        </a>
                                    </li>
                                })
                            }
                             {isAssociationAdmin &&
                                associationAdminRoutes.map((route) => {
                                    return <li className={`${currentUrl.endsWith(route.path) ? 'active' : ''}`} onClick={(e) => this.handleClick(e)} >
                                        <b></b>
                                        <b></b>
                                        <a href={route.path} onClick={(e) => this.handleClick(e)}>
                                            <span className="icon">
                                                {!currentUrl.endsWith(route.path) ? route.icon : route.activeIcon}
                                            </span>
                                            <span className="title" onClick={(e) => this.handleClick(e)} >{route.label}</span>
                                        </a>
                                    </li>
                                })
                            }
                            {isSkl &&
                                schoolRoutes.map((route) => {
                                    return <li className={`${currentUrl.endsWith(route.path) ? 'active' : ''}`} onClick={(e) => this.handleClick(e)} >
                                        <b></b>
                                        <b></b>
                                        <a href={route.path} onClick={(e) => this.handleClick(e)}>
                                            <span className="icon">
                                                {!currentUrl.endsWith(route.path) ? route.icon : route.activeIcon}
                                            </span>
                                            <span className="title" onClick={(e) => this.handleClick(e)} >{route.label}</span>
                                        </a>
                                    </li>
                                })
                            }
                        </ul>
                    </nav>
                </aside>
            </>
        );
    }
}
export default SideBar;