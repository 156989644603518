import React from "react";
import { DatePicker, Divider, Select, Space, Table } from "antd";
import { EditFilled, ShareAltOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { getClaims, RRServer } from "../../../utils/lib";
import { ClassScheduleInterface, CorporateInterface, UserInterface } from "../../../types";
import dayjs from "dayjs";
import {
  DateColumnFilter,
  SelectColumnFilter,
  TimeColumnFilter,
} from "../../TableComp/Filter";
import TableContainer from "../../TableComp/TableContainer";
import TopBar from "../../TopBar";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;

interface DrivingSchoolInterface {
  code: string;
  id: string;
  drivingSchoolName: string;
  currentCount: string;
}

interface ClassSchedulesListStateProps {
  classSchedules: ClassScheduleInterface[];
  selectedSchedule: ClassScheduleInterface | null;
  userCode: string;
  usersHash: { [key: string]: UserInterface };
  filterSchoolId: string;
  filterUserId: string;
  schools: DrivingSchoolInterface[];
  filterDates: any;
  users: any[]
}

class ClassesList extends React.Component<any, ClassSchedulesListStateProps> {
  requesting: { [key: string]: boolean } = {};
  constructor(props: any) {
    super(props);
    this.state = {
      classSchedules: [],
      selectedSchedule: null,
      userCode: "",
      usersHash: {},
      filterSchoolId: "",
      filterUserId: "",
      filterDates: null,
      schools: [],
      users:[]
    };
    this.getSchool = this.getSchool.bind(this);
  }

  componentDidMount() {
    getClaims().then((claims) => {
      if (!claims || !!!claims["drivingSchoolId"]) {
        console.log("No Claim - cs");
        return null;
      }
      const drivingSchoolId = claims ? claims["drivingSchoolId"] : "*";
      // let promise = [
      //   drivingSchoolId === "*"
      //     ?  RRServer.list({ table: "class_schedules", forCurrentUser: false })
      //     : RRServer.list({
      //         table: "class_schedules",
      //         forCurrentUser: false,
      //         where: [["companyId", "==", drivingSchoolId]],
      //       }),
      // ];
      // promise.push(
      //   drivingSchoolId === "*"
      //     ? RRServer.list({ table: "driving_schools", forCurrentUser: false })
          
      //     : RRServer.list({
      //         table: "driving_schools",
      //         forCurrentUser: false,
      //         where: [["companyId", "==", drivingSchoolId]],
      //       })
      // );

      // Promise.all(promise).then((results: any) => {
      //   let classSchedules = results[0].data.data;
      //   let schools = results[1].data.data;
      //   this.setState({ classSchedules, schools });
      // });
    });
    const claimsStr = localStorage.getItem('claims');
    const claims = !!claimsStr ? JSON.parse(claimsStr) : {};
    const drivingSchoolId = claims.drivingSchoolId || '';

    function GetFormattedDate() {
      var todayTime = new Date();
      var month = todayTime.getMonth() + 1;
      var monthFormat = month.toString().length === 1 ? `0${month}` : month
      var day = todayTime.getDate();
      var dayFormat = day.toString().length === 1 ? `0${day}` : day
      var year = todayTime.getFullYear();
      return dayFormat + "/" + monthFormat + "/" + year;
    }

    let promiseArr: any = [];
        if (drivingSchoolId === "*") {
            promiseArr = [
                RRServer.list({ table: "scores", forCurrentUser: false }),
                RRServer.list({ table: "class_schedules", forCurrentUser: false }),
                RRServer.list({ table: "driving_schools", forCurrentUser: false }),
                RRServer.list({ table: "users", forCurrentUser: false }),
                RRServer.list({ table: RRServer.Tables.DRIVING_SCHOOLS, forCurrentUser: false }),
                RRServer.list({ table: RRServer.Tables.CORPORATES, forCurrentUser: false })
            ]
        } else {
            promiseArr = [
                RRServer.list({ table: "scores", forCurrentUser: false, where: [['cid', '==', drivingSchoolId]] }),
                RRServer.list({
                  table: "class_schedules",
                  forCurrentUser: false,
                  where: [["companyId", "==", drivingSchoolId]],
                }),
                RRServer.list({
                  table: "driving_schools",
                  forCurrentUser: false,
                  where: [["companyId", "==", drivingSchoolId]],
                }),
                RRServer.list({ table: "users", forCurrentUser: false, where: [['companyId', '==', drivingSchoolId]] }),
                RRServer.list({ table: RRServer.Tables.DRIVING_SCHOOLS, forCurrentUser: false, where: [['companyId', '==', drivingSchoolId]] }),
                RRServer.list({ table: RRServer.Tables.CORPORATES, forCurrentUser: false, where: [['companyId', '==', drivingSchoolId]] })
            ]
        }

        Promise.all(promiseArr)
            .then((results: any) => {
                const scores = results[0].data.data;
                let classSchedules = results[1].data.data;
                const users = results[3].data.data as UserInterface[];
                const schools = results[2].data.data as DrivingSchoolInterface[];
                const corporates = results[3].data.data as CorporateInterface[];

                let userHashed: { [key: string]: UserInterface } = {};
                users.map(user => userHashed[user.id] = user);

                let schoolsHashed: { [key: string]: DrivingSchoolInterface } = {};
                schools.map(school => schoolsHashed[school.id] = school);

                let corpHashed: { [key: string]: CorporateInterface } = {};
                corporates.map(school => corpHashed[school.id] = school);

                const updatedSchedules = classSchedules.map((c:any,idx:any)=>{
                  console.log(moment(c.date, "DD/MM/YYYY").isBefore(),'daayyy')

                  // isAfter && classStarted === false >> not attended
                  // isAfter && classStarted === true >> completed
                  //isBefore >> pending
                  //is same && classStarted === false  >> yet to board
                  // is same && classStarted === true>> on board

                  if(moment(c.date, "DD/MM/YYYY").isAfter() && c.hasOwnProperty('classStarted') === false){
                    return {
                      ...c,
                      status: 'Not attended'
                    }
                  } else if(c.hasOwnProperty('classStarted') === true) {
                    return {
                      ...c,
                      status: moment(c.date, "DD/MM/YYYY").isAfter() ?   'Completed' : "On Board"
                    }
                  } else if(moment(c.date, "DD/MM/YYYY").isBefore()){
                      return {
                        ...c,
                        status: "Pending"
                      }
                  } else  if(c.date === GetFormattedDate() && c.hasOwnProperty('classStarted') === false){
                    return {
                      ...c,
                      status:  "Yet to Board"
                    }
                  } else if(c.date === GetFormattedDate() && c.hasOwnProperty('classStarted') === true) {
                    return {
                      ...c,
                      status:  "On Going"
                    }
                  } else {
                    return {
                      ...c,
                      status: 'Yet to board'
                    }
                  }

                  // return c.classStarted? {
                  //   ...c,
                  //   status: 'On Board'
                  // } : {
                  //   ...c,
                  //   status:  'Yet to Board'
                  // }
                })

                this.setState({users, classSchedules : updatedSchedules})

                let usersHash: any = {};

                users.map((a: any) => (usersHash[a.id] = a));
          
                this.setState({
                  classSchedules: this.state.classSchedules.map(
                    (c: any) => {( (c.studentId = usersHash[c.studentId]?  usersHash[c.studentId].typeOfVehicle : '')); return c}
                  ),
                  users: users,
                });
            })
    
  }




  getSchool(schoolId: string) {
    const school = this.state.classSchedules.filter(
      (school) => (school.id = schoolId)
    );
    return school[0];
  }

  getUser = (id: string) => {
    if (this.requesting[id]) {
      return;
    }
    this.requesting[id] = true;
    RRServer.get({ table: "users", id }).then((result: any) => {
      let usersHash = this.state.usersHash;
      usersHash[id] = result.data.data;
      this.setState({
        usersHash,
      });
    });
  };

  render() {
    const {
      classSchedules,
      filterSchoolId,
      filterUserId,
      filterDates,
      schools,
    } = this.state;
    // let { url } = this.props.match;

    let dataSource = classSchedules.map((school:any) => ({
      key: school.id,
      ...school,
    }));

    if (filterSchoolId) {
      dataSource = dataSource.filter((d) => d.companyId === filterSchoolId);
    }
    if (filterUserId) {
      dataSource = dataSource.filter((d) => d.studentId === filterUserId);
    }
    if (filterDates) {
     
      dataSource = dataSource.filter(item => {
        let itemDate = item.date.split('/').reverse().join("/")
        let startDateModified = filterDates[0].split('/').reverse().join("/")
        let endDateModified = filterDates[1].split('/').reverse().join("/")
       return (new Date(itemDate).getTime() >= new Date(startDateModified).getTime() && new Date(itemDate).getTime() <= new Date(endDateModified).getTime());
     })

    }

    const tConv24 = (time24 : any) => {
      var ts = time24;
      var H = +ts.substr(0, 2);
      var h : any = (H % 12) || 12;
      h = (h < 10)?("0"+h):h;  // leading 0 at the left for 1 digit hours
      var ampm = H < 12 ? " AM" : " PM";
      ts = h + ts.substr(2, 3) + ampm;
      return ts;
    };


    const columns = [
      {
        Header: "Schools",
        accessor: "school",
        Filter: SelectColumnFilter,
        // disableSortBy: true
      },
      {
        Header: "Instructor",
        accessor: "instructor",
        Filter: SelectColumnFilter,
        // disableSortBy: true
      },
      {
        Header: "Category",
        accessor: "studentId",
        Filter: SelectColumnFilter,
        // disableSortBy: true
      },
      {
        Header: "User",
        accessor: "student",
        // disableFilters: true
      },

      {
        Header: "Date",
        accessor: "date",
        Filter: DateColumnFilter,
      },

      {
        Header: "Time",
        accessor: "time.startTime",
        Filter: TimeColumnFilter,
 
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: SelectColumnFilter,

      },
      {
        Header: "Rating",
        accessor: "rating",
      },
      {
        Header: "Comments",
        accessor: "remarks",
      },
   
    ];
    let uniqueStudentsHash: { [key: string]: ClassScheduleInterface } = {};
    classSchedules.map((csh) => (uniqueStudentsHash[csh.studentId] = csh));
    const uniqueStudents = Object.values(uniqueStudentsHash);
    console.log('class',this.state.classSchedules)
    return (
      <>
        <TopBar title="Classes" />

        <div className="contentContainer">
          <div className="contentTop">
            <h5>Class List</h5>
            <RangePicker format={"DD/MM/YYYY"} onChange={(date,dateString)=>this.setState({filterDates: dateString})}/>

          </div>

          <div className="table show">
    
            <TableContainer columns={columns} data={dataSource} />

          </div>

          {/* Modal Starts */}
          <div
            className="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Comment
                  </h5>
                  {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                </div>
                <div className="modal-body">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  {/* <button type="button" className="btn btn-primary">Understood</button> */}
                </div>
              </div>
            </div>
          </div>
          {/* Modal Ends */}
          <div
            className="modal fade"
            id="exampleModal"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Modal title
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-4">
                      <label htmlFor="Name">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value="Data one"
                      />
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="Name">Due Date</label>
                      <input type="date" className="form-control" />
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="Name">Status</label>
                      <input type="text" className="form-control" value="1" />
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="Name">Paid Date</label>
                      <input type="date" className="form-control" />
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="Name">Rating</label>
                      <select name="rating" id="" className="form-control">
                        <option value="">--Rating--</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="button" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="exampleModal1"
            aria-labelledby="exampleModalLabel1"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Modal title
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <table className="table table-hover">
                    <tr>
                      <th>Name</th>
                      <th>Due Date</th>
                      <th>Status</th>
                      <th>Paid Date</th>
                      <th>Rating</th>
                    </tr>
                    <tr>
                      <td>Data one</td>
                      <td>07-05-2021</td>
                      <td>Paid</td>
                      <td>12-12-22</td>
                      <td>1</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                {/* <button type="button" className="btn btn-primary">Save changes</button> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClassesList;
