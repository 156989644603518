import React from 'react';
import { Button, Divider, Form, Input, message, Select, Skeleton } from 'antd';
// import { withRouter } from 'react-router-dom';
// import MaskedInput from 'antd-mask-input'
import { callOnServer, RRServer } from '../../../utils/lib';
import TextArea from 'antd/lib/input/TextArea';
import FormComponent from '../../FormComponent/FormComponent';


interface DrivingSchoolInterface {
    code: string;
    id: string
    drivingSchoolName: string
    currentCount: string
    sosNumber: string
}
interface CorporateCodeInterface {
    password: string
    id: string
}

interface DrivingSchoolAddInterface {
    currentSchool: null | DrivingSchoolInterface,
    corporateCode: null | CorporateCodeInterface
    mode: 'edit' | 'add',
}

const fields: any = [
    { type: 'select', name: 'destination', label: 'Destination', options: [{value:'ap',label:'Asian Paints'}, {value:'iocl',label:'IOCL'},{value:'ds',label:'Driving School'},{value:'ds2',label:'Driving School_2W'},{value:'ds4',label:'Driving School_4W'},{value:'dsTN',label:'Driving School_TN'}], isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
    { type: 'select', name: 'lang', label: 'Language', options: [ {value:"tamil",label:"Tamil"},{value:"malayalam",label:"malayalam"},{value:"telugu",label:"telugu"},{value:"kannada",label:"kannada"},{value:"english",label:"english"}], isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
    { type: 'text', name: 'title', label: 'Title', isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
    { type: 'text', name: 'content', label: 'Content', isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
    { type: 'text', name: 'fileUrl', label: 'File Url', isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
    { type: 'text', name: 'videoUrl', label: 'video Url', isMandatory: false, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
    { type: 'date', name: 'publishDate', label: 'PublishDate', isMandatory: true, gridMd: 12, gridSm: 12, validation: (value: any) => !value ? false : true },
]
const associationFields: any = [
    { type: 'select', name: 'destination', label: 'Destination', options: [{value:'dsTN',label:'Driving School_TN'}], isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
    { type: 'select', name: 'lang', label: 'Language', options: ['Tamil', 'Malayalam', 'Telugu', 'Kannada', 'English'], isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
    { type: 'text', name: 'title', label: 'Title', isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
    { type: 'text', name: 'content', label: 'Content', isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
    { type: 'text', name: 'fileUrl', label: 'File Url', isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
    { type: 'text', name: 'videoUrl', label: 'video Url', isMandatory: false, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
    { type: 'date', name: 'publishDate', label: 'PublishDate', isMandatory: true, gridMd: 12, gridSm: 12, validation: (value: any) => !value ? false : true },
]

class NewsAdd extends React.Component<any, DrivingSchoolAddInterface> {
    constructor(props: any) {
        super(props);
        this.state = {
            currentSchool: null,
            corporateCode: null,
            // mode: !!props.match.params.id ? 'edit' : 'add',
            mode: 'add',
        }
        this.onFinish = this.onFinish.bind(this);
    }

    componentDidMount() {
        // if (this.state.mode === 'edit') {
        //     Promise.all([
        //         RRServer.get({ table: 'driving_schools', id: this.props.match.params.id }),
        //         callOnServer('find', { table: 'corporate_codes', where: [['companyId', '==', this.props.match.params.id]] })
        //     ]).then((results: any) => {
        //         this.setState({
        //             currentSchool: results[0].data.data,
        //             corporateCode: results[1].data.data
        //         })
        //     })
        // }
        
      
    }

    addArticle = (values: any) => {
        console.log('news val',values)

        RRServer.add({table:"news_articles",data: {...values}})
            .then((result: any) => {
                console.log(result);
                if (result.data.s) {
                    alert('Successfully Saved News Article');
                    // window.location.reload();
                }
            })
    }

    onFinish(values: any) {
        this.addArticle({table:"news_articles",...values});
    };

    onFinishFailed(errorInfo: any) {
        console.log('Failed:', errorInfo);
    };

    onSearch() {
    }

    toggleForm = (e: any) => {
        e.preventDefault();
        // console.log(this.state.isToggle);
    }

    render() {
        const { mode, currentSchool, corporateCode } = this.state

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };
        const tailLayout = {
            wrapperCol: { offset: 8, span: 16 },
        };

        const { Option } = Select;
        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        const isLoggedIn = localStorage.getItem('claims');
        const isSuperAdmin = claims.drivingSchoolId === "*"? true : false
        const isClusterAdmin = claims.drivingSchoolId === "#" && claims?.userType === "CLUSTER"? true : false
        const isAssociationAdmin = claims.drivingSchoolId === "#" && claims?.userType === "ASSOCIATION"? true : false

        return (
            <>
            {isSuperAdmin &&   <FormComponent fields={fields} toggleForm={(e: any) => this.toggleForm(e)} handleValues={this.onFinish} buttonText='ADD' />}
            {isAssociationAdmin &&   <FormComponent fields={associationFields} toggleForm={(e: any) => this.toggleForm(e)} handleValues={this.onFinish} buttonText='ADD' />}

            </>
        );
    }
}

export default NewsAdd;
