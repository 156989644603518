import React, { Component } from 'react';
// import Form from '../form/Form';
import FormComponent from '../FormComponent/FormComponent';
// import { SelectColumnFilter } from '../TableComp/Filter';
import { SelectColumnFilter, Filter } from '../TableComp/Filter';
import TableContainer from '../TableComp/TableContainer';
import TopBar from '../TopBar';


const fields: any = [
    { type: 'text', name: 'identifier', label: 'Identifier', isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
    { type: 'text', name: 'name', label: 'Name', isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
    { type: 'text', name: 'password', label: 'Password', isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
    { type: 'text', name: 'code', label: 'Code', isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
]

const columns = [
    {
        Header: 'Name',
        accessor: 'name',
        Filter: SelectColumnFilter,
        // disableSortBy: true
    },
    {
        Header: 'Code',
        accessor: 'code',
        // disableFilters: true
    },
    {
        Header: 'Current Count',
        accessor: 'currentCount',
    },
    {
        Header: 'Action',
        accessor: "action",
        Filter: true
    }
]

const Data = [
    {
        id: '1', name: 'one', code: 'praveen', currentCount: 'asasddsa@rmai.com', action: <div style={{ display: 'flex', justifyContent: "space-around", cursor: "not-allowed" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
            </svg>
        </div>
    },
    {
        id: '2', name: 'two', code: 'praveen', currentCount: 'asasddsa@rmai.com', action: <div style={{ display: 'flex', justifyContent: "space-around", cursor: "not-allowed" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
            </svg>
        </div>
    },
    {
        id: '3', name: 'three', code: 'praveen', currentCount: 'asasddsa@rmai.com', action: <div style={{ display: 'flex', justifyContent: "space-around", cursor: "not-allowed" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
            </svg>
        </div>
    },
    {
        id: '5', name: 'fivee', code: 'praveen', currentCount: 'asasddsa@rmai.com', action: <div style={{ display: 'flex', justifyContent: "space-around", cursor: "not-allowed" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
            </svg>
        </div>
    },
]

interface IsState {
    isToggle: boolean,
    isSearch: boolean
}

class Corporate extends Component {

    state: IsState = {
        isToggle: true,
        isSearch: false
    }

    toggleForm = (e: any) => {
        e.preventDefault();
        this.setState({ isToggle: !this.state.isToggle });
        // console.log(this.state.isToggle);
    }

    handleValues = (values: any) => {
        console.log(values)
    }

    render() {
        const { isToggle } = this.state;

        return (
            <>
                <TopBar title="Corporate" />

                <div className='contentContainer'>
                    <div className='contentTop'>
                        <h5>{this.state.isToggle ? 'Corporate List' : 'Add Corporate'}</h5>

                        <div className='tableBtn' style={{ marginLeft: "auto" }}>
                            <button className='btn btn-primary' onClick={(e) => this.toggleForm(e)}>{this.state.isToggle ? 'ADD CORPORATE' : 'CANCEL'}</button>
                        </div>
                    </div>
                    <div className={`${isToggle ? 'table show' : 'table hide'}`}>
                        <TableContainer columns={columns} data={Data} />
                    </div>
                    <div className={`${isToggle ? `form hide` : `form show`}`}>
                        <FormComponent fields={fields} toggleForm={(e: any) => this.toggleForm(e)} handleValues={this.handleValues} buttonText='Submit' />
                    </div>
                </div>
            </>
        );
    }
}

export default Corporate;