import { message, TimePicker } from 'antd';
import React, { Component } from 'react';
import Slider from 'react-slick';


interface AddInstructorFormState {
    showMondayTiming: boolean,
    isMondayTimingAdd: boolean,
    showTuesdayTiming: boolean,
    isTuesdayTimingAdd: boolean,
    showWednesdayTiming: boolean,
    isWednesdayTimingAdd: boolean,
    showThursdayTiming: boolean,
    isThursdayTimingAdd: boolean,
    showFridayTiming: boolean,
    isFridayTimingAdd: boolean,
    showSaturdayTiming: boolean,
    isSaturdayTimingAdd: boolean,
    showSundayTiming: boolean,
    isSundayTimingAdd: boolean,

    
    monday: any,
    tuesday: any,
    wednesday: any,
    thursday: any,
    friday: any,
    saturday: any,
    sunday: any,

    startTime: string,
    endTime: string,
}

interface AddInstructorFormProps {
    handleSlots : any,
}

class AddInstructorForm extends Component<AddInstructorFormProps,AddInstructorFormState> {

    state : AddInstructorFormState = {
        showMondayTiming: false,
        isMondayTimingAdd: false,
        showTuesdayTiming: false,
        isTuesdayTimingAdd: false,
        showWednesdayTiming: false,
        isWednesdayTimingAdd: false,
        showThursdayTiming: false,
        isThursdayTimingAdd: false,
        showFridayTiming: false,
        isFridayTimingAdd: false,
        showSaturdayTiming: false,
        isSaturdayTimingAdd: false,
        showSundayTiming: false,
        isSundayTimingAdd: false,

        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday:[],
        sunday: [],

        startTime:'',
        endTime:""
    }

    addMondayTiming = (e: any) => {
        e.preventDefault();
        const newTiming = { endTime: this.state.endTime, startTime: this.state.startTime, slots: 4 }

        if(this.state.monday.some((obj:any)=>obj.startTime === newTiming.startTime && obj.endTime === newTiming.endTime)){
           message.warning('Timing already declared')
        } else {
            this.setState({ monday: [...this.state.monday, newTiming] })
            this.setState({ isMondayTimingAdd: false })    
        }
    }
    addTuesdayTiming = (e: any) => {
        e.preventDefault();
        const newTiming = { startTime: this.state.startTime, endTime: this.state.endTime, slots: 4 }
        if(this.state.tuesday.some((obj:any)=>obj.startTime === newTiming.startTime && obj.endTime === newTiming.endTime)){
            message.warning('Timing already declared')
         } else {
             this.setState({ tuesday: [...this.state.tuesday, newTiming] })
             this.setState({ isTuesdayTimingAdd: false })    
         }
    }
    addWednesdayTiming = (e: any) => {
        e.preventDefault();
        const newTiming = { startTime: this.state.startTime, endTime: this.state.endTime , slots: 4}
        if(this.state.wednesday.some((obj:any)=>obj.startTime === newTiming.startTime && obj.endTime === newTiming.endTime)){
            message.warning('Timing already declared')
         } else {
             this.setState({ wednesday: [...this.state.wednesday, newTiming] })
             this.setState({ isWednesdayTimingAdd: false })    
         }
    }
    addThursdayTiming = (e: any) => {
        e.preventDefault();
        const newTiming = { startTime: this.state.startTime, endTime: this.state.endTime, slots: 4 }
        if(this.state.thursday.some((obj:any)=>obj.startTime === newTiming.startTime && obj.endTime === newTiming.endTime)){
            message.warning('Timing already declared')
         } else {
             this.setState({ thursday: [...this.state.thursday, newTiming] })
             this.setState({ isThursdayTimingAdd: false })    
         }
    }
    addFridayTiming = (e: any) => {
        e.preventDefault();
        const newTiming = { startTime: this.state.startTime, endTime: this.state.endTime , slots: 4}
        if(this.state.friday.some((obj:any)=>obj.startTime === newTiming.startTime && obj.endTime === newTiming.endTime)){
            message.warning('Timing already declared')
         } else {
             this.setState({ friday: [...this.state.friday, newTiming] })
             this.setState({ isFridayTimingAdd: false })    
         }
    }
    addSaturdayTiming = (e: any) => {
        e.preventDefault();
        const newTiming = { startTime: this.state.startTime, endTime: this.state.endTime , slots: 4}
        if(this.state.saturday.some((obj:any)=>obj.startTime === newTiming.startTime && obj.endTime === newTiming.endTime)){
            message.warning('Timing already declared')
         } else {
             this.setState({ saturday: [...this.state.saturday, newTiming] })
             this.setState({ isSaturdayTimingAdd: false })    
         }
    }
    addSundayTiming = (e: any) => {
        e.preventDefault();
        const newTiming = { startTime: this.state.startTime, endTime: this.state.endTime , slots: 4}
        if(this.state.sunday.some((obj:any)=>obj.startTime === newTiming.startTime && obj.endTime === newTiming.endTime)){
            message.warning('Timing already declared')
         } else {
             this.setState({ sunday: [...this.state.sunday, newTiming] })
             this.setState({ isSundayTimingAdd: false })    
         }
    }

    submitInstructorSlots = () =>{
       const {monday, tuesday, wednesday, thursday, friday, saturday, sunday} = this.state

        this.props.handleSlots({
            Sun: sunday,
            Mon: monday,
            Tue: tuesday,
            Wed: wednesday,
            Thu: thursday,
            Fri: friday,
            Sat: saturday
        })
    }

    render() {

        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            adaptiveHeight: true,
        };
        
        return (
            <div className="formTwoWrapper">
            <div className="formWrapper">
            </div>
            <div className="cardPreview instructor">
                <div className='instructorTable'>
                    <div className='instructorForm'>
                        <div className='inputCard'>
                            <h2>Monday</h2>
                            {!this.state.showMondayTiming && <svg xmlns="http://www.w3.org/2000/svg" onClick={() => this.setState({ showMondayTiming: !this.state.showMondayTiming })} width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                            </svg>}
                            {this.state.showMondayTiming && <svg xmlns="http://www.w3.org/2000/svg" onClick={() => this.setState({ showMondayTiming: !this.state.showMondayTiming })} width="16" height="16" fill="currentColor" className="bi bi-dash-lg" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z" />
                            </svg>}
                            <div className={`timingContainer ${this.state.showMondayTiming && `show`}`}>
                                {this.state.monday.map((timing: any) => {
                                    return <span className='timingSlots'>{timing.startTime} - {timing.endTime} <span className='hoverDelete' onClick={(e) => { this.setState({ monday: this.state.monday.filter((val: any) => val.startTime !== timing.startTime) }) }} style={{cursor:"pointer"}}> x </span> </span>
                                })}
                                {this.state.isMondayTimingAdd && <>
                                    <form>
                                    <TimePicker.RangePicker format="h:mm a" size={`large`} onChange={(time, timestring) => this.setState({ startTime: timestring[0], endTime: timestring[1] })} />
                                        <button onClick={(e) => this.addMondayTiming(e)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
                                                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                            </svg>
                                        </button>
                                    </form>
                                </>}
                                {!this.state.isMondayTimingAdd && <svg xmlns="http://www.w3.org/2000/svg" onClick={() => this.setState({ isMondayTimingAdd: !this.state.isMondayTimingAdd })} width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg>}
                            </div>
                        </div>

                        <div className='inputCard'>
                            <h2>Tuesday</h2>
                            {!this.state.showTuesdayTiming && <svg xmlns="http://www.w3.org/2000/svg" onClick={() => this.setState({ showTuesdayTiming: !this.state.showTuesdayTiming })} width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                            </svg>}
                            {this.state.showTuesdayTiming && <svg xmlns="http://www.w3.org/2000/svg" onClick={() => this.setState({ showTuesdayTiming: !this.state.showTuesdayTiming })} width="16" height="16" fill="currentColor" className="bi bi-dash-lg" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z" />
                            </svg>}
                            <div className={`timingContainer ${this.state.showTuesdayTiming && `show`}`}>
                                {this.state.tuesday.map((timing: any) => {
                                    return <span className='timingSlots'>{timing.startTime} - {timing.endTime} <span className='hoverDelete' onClick={(e) => { this.setState({ tuesday: this.state.tuesday.filter((val: any) => val.startTime !== timing.startTime) }) }}> x </span></span>
                                })}
                                {this.state.isTuesdayTimingAdd && <>
                                    <form>
                                    <TimePicker.RangePicker format="h:mm a" size={`large`} onChange={(time, timestring) => this.setState({ startTime: timestring[0], endTime: timestring[1] })} />
                                        <button onClick={(e) => this.addTuesdayTiming(e)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
                                                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                            </svg>
                                        </button>
                                    </form>
                                </>}
                                {!this.state.isTuesdayTimingAdd && <svg xmlns="http://www.w3.org/2000/svg" onClick={() => this.setState({ isTuesdayTimingAdd: !this.state.isTuesdayTimingAdd })} width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg>}
                            </div>
                        </div>

                        <div className='inputCard'>
                            <h2>Wednesday</h2>
                            {!this.state.showWednesdayTiming && <svg xmlns="http://www.w3.org/2000/svg" onClick={() => this.setState({ showWednesdayTiming: !this.state.showWednesdayTiming })} width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                            </svg>}
                            {this.state.showWednesdayTiming && <svg xmlns="http://www.w3.org/2000/svg" onClick={() => this.setState({ showWednesdayTiming: !this.state.showWednesdayTiming })} width="16" height="16" fill="currentColor" className="bi bi-dash-lg" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z" />
                            </svg>}
                            <div className={`timingContainer ${this.state.showWednesdayTiming && `show`}`}>
                                {this.state.wednesday.map((timing: any) => {
                                    return <span className='timingSlots'>{timing.startTime} - {timing.endTime} <span className='hoverDelete' onClick={(e) => { this.setState({ wednesday: this.state.wednesday.filter((val: any) => val.startTime !== timing.startTime) }) }}> x </span></span>
                                })}
                                {this.state.isWednesdayTimingAdd && <>
                                    <form>
                                    <TimePicker.RangePicker format="h:mm a" size={`large`} onChange={(time, timestring) => this.setState({ startTime: timestring[0], endTime: timestring[1] })} />
                                        <button onClick={(e) => this.addWednesdayTiming(e)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
                                                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                            </svg>
                                        </button>
                                    </form>
                                </>}
                                {!this.state.isWednesdayTimingAdd && <svg xmlns="http://www.w3.org/2000/svg" onClick={() => this.setState({ isWednesdayTimingAdd: !this.state.isWednesdayTimingAdd })} width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg>}
                            </div>
                        </div>

                        <div className='inputCard'>
                            <h2>Thursday</h2>
                            {!this.state.showThursdayTiming && <svg xmlns="http://www.w3.org/2000/svg" onClick={() => this.setState({ showThursdayTiming: !this.state.showThursdayTiming })} width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                            </svg>}
                            {this.state.showThursdayTiming && <svg xmlns="http://www.w3.org/2000/svg" onClick={() => this.setState({ showThursdayTiming: !this.state.showThursdayTiming })} width="16" height="16" fill="currentColor" className="bi bi-dash-lg" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z" />
                            </svg>}
                            <div className={`timingContainer ${this.state.showThursdayTiming && `show`}`}>
                                {this.state.thursday.map((timing: any) => {
                                    return <span className='timingSlots'>{timing.startTime} - {timing.endTime} <span className='hoverDelete' onClick={(e) => { this.setState({ thursday: this.state.thursday.filter((val: any) => val.startTime !== timing.startTime) }) }}> x </span></span>
                                })}
                                {this.state.isThursdayTimingAdd && <>
                                    <form>
                                    <TimePicker.RangePicker format="h:mm a" size={`large`} onChange={(time, timestring) => this.setState({ startTime: timestring[0], endTime: timestring[1] })} />
                                        <button onClick={(e) => this.addThursdayTiming(e)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
                                                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                            </svg>
                                        </button>
                                    </form>
                                </>}
                                {!this.state.isThursdayTimingAdd && <svg xmlns="http://www.w3.org/2000/svg" onClick={() => this.setState({ isThursdayTimingAdd: !this.state.isThursdayTimingAdd })} width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg>}
                            </div>
                        </div>

                        <div className='inputCard'>
                            <h2>Friday</h2>
                            {!this.state.showFridayTiming && <svg xmlns="http://www.w3.org/2000/svg" onClick={() => this.setState({ showFridayTiming: !this.state.showFridayTiming })} width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                            </svg>}
                            {this.state.showFridayTiming && <svg xmlns="http://www.w3.org/2000/svg" onClick={() => this.setState({ showFridayTiming: !this.state.showFridayTiming })} width="16" height="16" fill="currentColor" className="bi bi-dash-lg" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z" />
                            </svg>}
                            <div className={`timingContainer ${this.state.showFridayTiming && `show`}`}>
                                {this.state.friday.map((timing: any) => {
                                    return <span className='timingSlots'>{timing.startTime} - {timing.endTime} <span className='hoverDelete' onClick={(e) => { this.setState({ friday: this.state.friday.filter((val: any) => val.startTime !== timing.startTime) }) }}> x </span></span>
                                })}
                                {this.state.isFridayTimingAdd && <>
                                    <form>
                                    <TimePicker.RangePicker format="h:mm a" size={`large`} onChange={(time, timestring) => this.setState({ startTime: timestring[0], endTime: timestring[1] })} />
                                        <button onClick={(e) => this.addFridayTiming(e)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
                                                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                            </svg>
                                        </button>
                                    </form>
                                </>}
                                {!this.state.isFridayTimingAdd && <svg xmlns="http://www.w3.org/2000/svg" onClick={() => this.setState({ isFridayTimingAdd: !this.state.isFridayTimingAdd })} width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg>}
                            </div>
                        </div>

                        <div className='inputCard'>
                            <h2>Saturday</h2>
                            {!this.state.showSaturdayTiming && <svg xmlns="http://www.w3.org/2000/svg" onClick={() => this.setState({ showSaturdayTiming: !this.state.showSaturdayTiming })} width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                            </svg>}
                            {this.state.showSaturdayTiming && <svg xmlns="http://www.w3.org/2000/svg" onClick={() => this.setState({ showSaturdayTiming: !this.state.showSaturdayTiming })} width="16" height="16" fill="currentColor" className="bi bi-dash-lg" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z" />
                            </svg>}
                            <div className={`timingContainer ${this.state.showSaturdayTiming && `show`}`}>
                                {this.state.saturday.map((timing: any) => {
                                    return <span className='timingSlots'>{timing.startTime} - {timing.endTime} <span className='hoverDelete' onClick={(e) => { this.setState({ saturday: this.state.saturday.filter((val: any) => val.startTime !== timing.startTime) }) }}> x </span></span>
                                })}
                                {this.state.isSaturdayTimingAdd && <>
                                    <form>
                                    <TimePicker.RangePicker format="h:mm a" size={`large`} onChange={(time, timestring) => this.setState({ startTime: timestring[0], endTime: timestring[1] })} />
                                        <button onClick={(e) => this.addSaturdayTiming(e)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
                                                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                            </svg>
                                        </button>
                                    </form>
                                </>}
                                {!this.state.isSaturdayTimingAdd && <svg xmlns="http://www.w3.org/2000/svg" onClick={() => this.setState({ isSaturdayTimingAdd: !this.state.isSaturdayTimingAdd })} width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg>}
                            </div>
                        </div>

                        <div className='inputCard'>
                            <h2>Sunday</h2>
                            {!this.state.showSundayTiming && <svg xmlns="http://www.w3.org/2000/svg" onClick={() => this.setState({ showSundayTiming: !this.state.showSundayTiming })} width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                            </svg>}
                            {this.state.showSundayTiming && <svg xmlns="http://www.w3.org/2000/svg" onClick={() => this.setState({ showSundayTiming: !this.state.showSundayTiming })} width="16" height="16" fill="currentColor" className="bi bi-dash-lg" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z" />
                            </svg>}
                            <div className={`timingContainer ${this.state.showSundayTiming && `show`}`}>
                                {this.state.sunday.map((timing: any) => {
                                    return <span className='timingSlots'>{timing.startTime} - {timing.endTime} <span className='hoverDelete' onClick={(e) => { this.setState({ sunday: this.state.sunday.filter((val: any) => val.startTime !== timing.startTime) }) }}> x </span></span>
                                })}
                                {this.state.isSundayTimingAdd && <>
                                    <form>
                                    <TimePicker.RangePicker format="h:mm a" size={`large`} onChange={(time, timestring) => this.setState({ startTime: timestring[0], endTime: timestring[1] })} />
                                        <button onClick={(e) => this.addSundayTiming(e)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
                                                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                            </svg>
                                        </button>
                                    </form>
                                </>}
                                {!this.state.isSundayTimingAdd && <svg xmlns="http://www.w3.org/2000/svg" onClick={() => this.setState({ isSundayTimingAdd: !this.state.isSundayTimingAdd })} width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg>}
                            </div>
                        </div>


                    </div>

                    <div className='instructorView'>

                        <div className='tableFormat'>

                            <div className='row'>
                                <div className='title'>
                                    <p>Monday</p>
                                </div>
                                <Slider {...settings}>
                                    {this.state.monday.map((timing: any) => {
                                        return <div> <span className='timingSlots'>{timing.startTime} - {timing.endTime}</span></div>
                                    })}
                                </Slider>

                            </div>

                            <div className='row'>
                                <div className='title'>
                                    <p>Tuesday</p>
                                </div>
                                <Slider {...settings}>
                                    {this.state.tuesday.map((timing: any) => {
                                        return <div> <span className='timingSlots'>{timing.startTime} - {timing.endTime}</span></div>
                                    })}
                                </Slider>

                            </div>

                            <div className='row'>
                                <div className='title'>
                                    <p>Wednesday</p>
                                </div>
                                <Slider {...settings}>
                                    {this.state.wednesday.map((timing: any) => {
                                        return <div> <span className='timingSlots'>{timing.startTime} - {timing.endTime}</span></div>
                                    })}
                                </Slider>

                            </div>

                            <div className='row'>
                                <div className='title'>
                                    <p>Thursday</p>
                                </div>
                                <Slider {...settings}>
                                    {this.state.thursday.map((timing: any) => {
                                        return <div> <span className='timingSlots'>{timing.startTime} - {timing.endTime}</span></div>
                                    })}
                                </Slider>
                            </div>

                            <div className='row'>
                                <div className='title'>
                                    <p>Friday</p>
                                </div>
                                <Slider {...settings}>
                                    {this.state.friday.map((timing: any) => {
                                        return <div> <span className='timingSlots'>{timing.startTime} - {timing.endTime}</span></div>
                                    })}
                                </Slider>
           
                            </div>

                            <div className='row'>
                                <div className='title'>
                                    <p>Saturday</p>
                                </div>
                                <Slider {...settings}>
                                    {this.state.saturday.map((timing: any) => {
                                        return <div> <span className='timingSlots'>{timing.startTime} - {timing.endTime}</span></div>
                                    })}
                                </Slider>

                            </div>

                            <div className='row'>
                                <div className='title'>
                                    <p>Sunday</p>
                                </div>
                                <Slider {...settings}>
                                    {this.state.sunday.map((timing: any) => {
                                        return <div> <span className='timingSlots'>{timing.startTime} - {timing.endTime}</span></div>
                                    })}
                                </Slider>
  
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <button  className='btn btn-primary' onClick={()=>this.submitInstructorSlots()} type='submit'>Submit</button>

        </div>
        );
    }
}

export default AddInstructorForm;