import React from 'react';
import { Button, Divider, Form, Input, message, Skeleton } from 'antd';
import { callOnServer, RRServer } from '../../../utils/lib';
import FormComponent from '../../FormComponent/FormComponent';
import TopBar from '../../TopBar';


interface DrivingSchoolInterface {
    code: string;
    id: string
    drivingSchoolName: string
    currentCount: string
    sosNumber: string
    pricePerCode: number
    name: string
}
interface CorporateCodeInterface {
    password: string
    id: string
}

interface DrivingSchoolAddInterface {
    currentSchool: null | DrivingSchoolInterface,
    corporateCode: null | CorporateCodeInterface
    mode: 'edit' | 'add',
    isToggle: boolean,
    associations: any[]
}

class ClusterAdd extends React.Component<any, DrivingSchoolAddInterface> {
    constructor(props: any) {
        super(props);
        this.state = {
            currentSchool: null,
            corporateCode: null,
            mode: window.location.href.includes("edit")? 'edit' : 'add',
            isToggle: false,
            associations: []
        }
        this.onFinish = this.onFinish.bind(this);
    }

    componentDidMount() {

        var last = window.location.href.substring(window.location.href.lastIndexOf("/") + 1, window.location.href.length);


        if (this.state.mode === 'edit') {
            Promise.all([
                RRServer.get({ table: 'clusters', id: last }),
            ]).then((results: any) => {
                this.setState({
                    currentSchool: results[0].data.data,
                })
            })
        }

        RRServer.list({ table: 'associations', forCurrentUser: false }).then((result: any) => {
          let schools = result.data.data;
          this.setState({ associations: schools })
      });
    }


    addCluster = (cluster: any) => {
        const { mode, currentSchool, corporateCode } = this.state;


        if (mode === 'edit') {
            let _school = cluster;
            cluster.id = currentSchool!.id;
            Promise.all([
                RRServer.update({ ...cluster, table: 'clusters' }),
            ])
                .then((results: any) => {
                    console.log(results);
                    if (results[0].data.s && results[1].data.s) {
                        message.success('Successfully Updated Clusters');
                        setTimeout(() => window.location.href = '/clusters', 200)
                    }
                })

        } else {
            const params = {
                ...cluster,
            };



            RRServer.add({ table: 'clusters', data: params }).then((result: any) => {

                message.success('Successfully Added Cluster');
                // setTimeout(() => window.location.href = '/clusters', 200)
                // alert('Successfully Added User Driving School');
            })
        }
    }

    onFinish(values: any) {
        this.addCluster(values);
    };

    onFinishFailed(errorInfo: any) {
        console.log('Failed:', errorInfo);
    };

    onSearch() {
    }

    toggleForm = (e: any) => {
        e.preventDefault();
        this.setState({ isToggle: !this.state.isToggle });
        // console.log(this.state.isToggle);
      };
    
      handleValues = (values: any) => {
        console.log(values);
        // setTimeout(() => this.addDrivingSchool(values), 200);
      };



    render() {

        const addFields: any = [
            { type: 'text', name: 'name', label: 'Cluster Name', isMandatory: true, gridMd: 12, gridSm: 12, validation: (value: any) => !value ? false : true },
            { type: 'select', name: 'association', options:[...this.state.associations.map((association,idx)=>{
              return {value: association.id,label: association.name}
            })], label: 'Association Name', isMandatory: true, gridMd: 12, gridSm: 12, validation: (value: any) => !value ? false : true },

        ]

        const editFields: any = [
            { type: 'text', name: 'name',value: this.state.currentSchool?.name, label: 'Cluster Name', isMandatory: true, gridMd: 12, gridSm: 12, validation: (value: any) => !value ? false : true },
            { type: 'select', name: 'association',  options:[...this.state.associations.map((association,idx)=>{
              return {value: association.id,label: association.name}
            })], label: 'Association Name', isMandatory: true, gridMd: 12, gridSm: 12, validation: (value: any) => !value ? false : true }
        ]

        return (
            <>
            <TopBar title="Cluster" />
    
            <div className="contentContainer">
              <div className="contentTop">
                <h5>
                  {this.state.isToggle
                    ? "Cluster List"
                    : "Add Cluster"}
                </h5>
    
                <div className="tableBtn" style={{ marginLeft: "auto" }}>
                  {/* <button
                    className="btn btn-primary"
                    onClick={(e) => this.toggleForm(e)}
                  >
                    {this.state.isToggle ? "Add Cluster" : "CANCEL"}
                  </button> */}
                  <button
                    className="btn btn-primary"
                    onClick={(e) => window.location.href = 'cluster/add'}
                  >
                    {this.state.isToggle ? "Add Cluster" : "CANCEL"}
                  </button>
                </div>
              </div>
              <div
                className={`${this.state.isToggle ? "table show" : "table hide"}`}
              >
                {/* <TableContainer columns={columns} data={this.state.clusters} /> */}
              </div>
              <div className={`${this.state.isToggle ? `form hide` : `form show`}`}>
                {/* <DrivingSchoolAdd/> */}
                {/* <FormComponent
                  fields={fields}
                  toggleForm={(e: any) => this.toggleForm(e)}
                  handleValues={this.handleValues}
                  buttonText="Submit"
                /> */}
                {/* <ClusterAdd/> */}
                <FormComponent
        fields={this.state.mode === 'edit'? editFields: addFields}
        toggleForm={(e: any) => this.toggleForm(e)}
        handleValues={this.onFinish}
        buttonText="Submit"
      />
              </div>
            </div>
          </>
        ) 
    }
}

export default ClusterAdd;