import React, { Component } from 'react';
import styles from './FormComponent.module.css';
import Field from './Field';

interface FormComponentState {
    values: {}
}



interface FormComponentProps {
    fields: {}[],
    toggleForm: (e: any) => void,
    handleValues: any,
    buttonText: string
}

class FormComponent extends Component<FormComponentProps, FormComponentState> {

    state: FormComponentState = {
        values: {}
    }

    handleValue = ({ name, value }: any) => {
        this.setState({ values: { ...this.state.values, [name]: value } })
    }

    handleSubmit = (e: any) => {
        e.preventDefault();
        this.props.handleValues(this.state.values)
        this.props.toggleForm(e)
    }

    render() {
        return (
            <div className={styles.formWrapper}>
                <form className='container needs-validation' onSubmit={this.handleSubmit}>

                    <div className="row">

                        {this.props.fields.map((field: any, index: any) => {
                            return <Field
                                key={index}
                                type={field.type}
                                name={field.name}
                                label={field.label}
                                isMandatory={field.isMandatory}
                                options={field.options}
                                size={6}
                                value={field.value}
                                gridSm={field.gridSm}
                                gridMd={field.gridMd}
                                handleValue={this.handleValue}
                                validation={field.validation} />
                        })}

                    </div>

                    <div className="text-right">
                        <button className={styles.btn}  type='submit'>{this.props.buttonText}</button>
                    </div>
                </form>
            </div>
        );
    }
}

export default FormComponent;