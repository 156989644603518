import { Row, Col, Card, Tabs, Skeleton, Input } from "antd";
import Statistic from "antd/lib/statistic";
import firebase from "firebase";
import React from "react";
import {
  CorporateCodeInterface,
  DrivingSchoolInterface,
  UserInterface,
} from "../../../types";

import dayjs from "dayjs";
/*import DrivingSchoolAdd from "../DrivingSchools/DrivingSchoolAdd";*/

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
/*import Style from './StudentStats.module.css';*/
import { RRServer } from "../../../utils/lib";
// import APToolbar from "./AP-Buttons-Toolbar";
import Search from "antd/lib/input/Search";
import TableContainer from "../../TableComp/TableContainer";
import TopBar from "../../TopBar";
import { SelectColumnFilter } from "../../TableComp/Filter";
import StudentStatClone from "./StudentStatClone";

const { TabPane } = Tabs;

// const CORPORATE_CODE = "RKkKTihRtahnXPFWUN8O";

interface DashProps {
  schools: DrivingSchoolInterface[];
  users: UserInterface[];
  usersFiltered: UserInterface[];
  corporateCodes: CorporateCodeInterface[];
  corporateCodesCount: number;
  drivingSchoolCodesCount: number;
  studentsCount: number;
  instructorCount: number;
  adminCount: number;
  loading: boolean;
  currentStudentId: string;
  isDetails: boolean;
}
class StudentStatsClone extends React.Component<any, DashProps> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      schools: [],
      users: [],
      corporateCodes: [],
      corporateCodesCount: 0,
      drivingSchoolCodesCount: 0,
      studentsCount: 0,
      instructorCount: 0,
      adminCount: 0,
      usersFiltered: [],
      currentStudentId: "",
      isDetails: false,
    };
  }

  componentDidMount() {
    const claimsString = localStorage.getItem("claims") || "{}";
    const claims = JSON.parse(claimsString);
    claims.drivingSchoolId = claims.drivingSchoolId || "";

    if(claims.drivingSchoolId === "*") {
      RRServer.list({
        table: RRServer.Tables.USERS,
        forCurrentUser: false,
      }).then((r: any) => {
        const users = r.data.data.sort((a: UserInterface, b: UserInterface) =>
          a.code.localeCompare(b.code)
        );
        this.setState({ users, usersFiltered: users.filter((user:any)=>{
          return user.type === "DRIVING_SCHOOL_USER"
        }) });
      });
    } else {
      RRServer.list({
        table: RRServer.Tables.USERS,
        forCurrentUser: false,
        where: [["companyId", "==", claims.drivingSchoolId]],
      }).then((r: any) => {
        const users = r.data.data.sort((a: UserInterface, b: UserInterface) =>
          a.code.localeCompare(b.code)
        );
        this.setState({ users, usersFiltered: users.filter((user:any)=>{
          return user.type === "DRIVING_SCHOOL_USER"
        }) });
      });
    }

   
  }

  onSearch = (text: string) => {
    const { users } = this.state;

    const usersFiltered = users.filter(
      (u) => u.name.toLowerCase().indexOf(text.toLowerCase()) != -1
    );

    this.setState({ usersFiltered });
  };

  render() {
    const { users, usersFiltered } = this.state;

    const columns = [
      {
        Header: "Corporate ID",
        accessor: "code",
        Filter: SelectColumnFilter,
        // disableSortBy: true
        Cell: (val: any) => {
          return this.state.usersFiltered.map((user) => {
            if (val.value === user.code) {
              return (
                <p
                  style={{ cursor: "pointer" }}
                  key={user.id}
                  onClick={() => {
                    this.setState({
                      currentStudentId: user.id,
                      isDetails: true,
                    });
                  }}
                >
                  {user.code}
                </p>
              );
            }
          });
        },
        // Cell: (val:any) =>   {return usersFiltered.map(user => <a key={user.id} href={'/student-stat/' + user.id}>{user.code}</a>
      },
      {
        Header: "Student Name",
        accessor: "name",
      },
    ];

   


    return (
      <>
        <TopBar title="Student Stats" />
        <div className="contentContainer">
 {         <div className="contentTop">
           {!this.state.isDetails && <h5>Student Stats</h5>}
            {/* <div className="tableBtn" style={{ marginLeft: "auto" }}>
              { <CSVLink data={Data} headers={headers} className='btn btn-primary' style={stylesBtn()} >Export CSV</CSVLink> }
            </div> */}
            {this.state.isDetails && <div className="tableBtn" style={{ marginLeft: "auto" }}>
              { <button
                className="btn btn-primary"
                onClick={(e) => this.setState({isDetails: false, currentStudentId:""})}
              >
                {this.state.isDetails && "BACK"}
              </button>}
            </div>}
          </div>}
          <div className="table show">
            {!this.state.isDetails && (
              <TableContainer
                columns={columns}
                data={this.state.usersFiltered}
              />
            )}
            {this.state.isDetails && this.state.currentStudentId && (
              <StudentStatClone studentId={this.state.currentStudentId}/>
            )}
          </div>
        </div>
        ]{" "}
      </>
    );
  }
}

export default StudentStatsClone;
