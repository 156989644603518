import React from "react";
import { DatePicker, Divider, Select, Space, Table } from "antd";
import { EditFilled, ShareAltOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { getClaims, RRServer } from "../../../utils/lib";
import { ClassScheduleInterface, UserInterface } from "../../../types";
import dayjs from "dayjs";
import {
  DateColumnFilter,
  SelectColumnFilter,
  TimeColumnFilter,
} from "../../TableComp/Filter";
import TableContainer from "../../TableComp/TableContainer";
import TopBar from "../../TopBar";
import LiveClasses from './LiveClasses';
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;

interface DrivingSchoolInterface {
  code: string;
  id: string;
  drivingSchoolName: string;
  currentCount: string;
}

interface ClassSchedulesListStateProps {
  classSchedules: ClassScheduleInterface[];
  selectedSchedule: ClassScheduleInterface | null;
  userCode: string;
  usersHash: { [key: string]: UserInterface };
  filterSchoolId: string;
  filterUserId: string;
  schools: DrivingSchoolInterface[];
  filterDates: any;
}

class LiveClassesClone extends React.Component<any, ClassSchedulesListStateProps> {
  requesting: { [key: string]: boolean } = {};
  constructor(props: any) {
    super(props);
    this.state = {
      classSchedules: [],
      selectedSchedule: null,
      userCode: "",
      usersHash: {},
      filterSchoolId: "",
      filterUserId: "",
      filterDates: null,
      schools: [],
    };
    this.getSchool = this.getSchool.bind(this);
  }

  

  componentDidMount() {
    getClaims().then((claims) => {
      if (!claims || !!!claims["drivingSchoolId"]) {
        console.log("No Claim - cs");
        return null;
      }
      const drivingSchoolId = claims ? claims["drivingSchoolId"] : "*";
      let promise = [
        drivingSchoolId === "*"
          ? RRServer.list({ table: "class_schedules", forCurrentUser: false })
          : RRServer.list({
              table: "class_schedules",
              forCurrentUser: false,
              where: [["companyId", "==", drivingSchoolId]],
            }),
      ];
      promise.push(
        drivingSchoolId === "*"
          ? RRServer.list({ table: "driving_schools", forCurrentUser: false })
          : RRServer.list({
              table: "driving_schools",
              forCurrentUser: false,
              where: [["companyId", "==", drivingSchoolId]],
            })
      );

      Promise.all(promise).then((results: any) => {
        let classSchedules = results[0].data.data;
        let schools = results[1].data.data;

        
      function GetFormattedDate() {
        var todayTime = new Date();
        var month = todayTime.getMonth() + 1;
        var monthFormat = month.toString().length === 1? `0${month}`: month
        var day = todayTime.getDate();
        var dayFormat = day.toString().length === 1? `0${day}`: day
        var year = todayTime.getFullYear();
        return  dayFormat + "/" + monthFormat  + "/" + year;
    }

        const updatedSchedules = classSchedules.map((c:any,idx:any)=>{
          if(moment(c.date, "DD/MM/YYYY").isAfter() && c.hasOwnProperty('classStarted') === false){
            return {
              ...c,
              status: 'Not attended'
            }
          } else if(c.hasOwnProperty('classStarted') === true) {
            return {
              ...c,
              status: moment(c.date, "DD/MM/YYYY").isAfter() ?   'Completed' : "On Board"
            }
          } else if(moment(c.date, "DD/MM/YYYY").isBefore()){
              return {
                ...c,
                status: "Pending"
              }
          } else  if(c.date === GetFormattedDate() && c.hasOwnProperty('classStarted') === false){
            return {
              ...c,
              status:  "Yet to Board"
            }
          } else if(c.date === GetFormattedDate() && c.hasOwnProperty('classStarted') === true) {
            return {
              ...c,
              status:  "On Going"
            }
          } else {
            return {
              ...c,
              status: 'Yet to board'
            }
          }
        })

        this.setState({ classSchedules: updatedSchedules, schools });
      });
    });
  }

  getSchool(schoolId: string) {
    const school = this.state.classSchedules.filter(
      (school) => (school.id = schoolId)
    );
    return school[0];
  }

  getUser = (id: string) => {
    if (this.requesting[id]) {
      return;
    }
    this.requesting[id] = true;
    RRServer.get({ table: "users", id }).then((result: any) => {
      let usersHash = this.state.usersHash;
      usersHash[id] = result.data.data;
      this.setState({
        usersHash,
      });
    });
  };

  render() {
    const {
      classSchedules,
      filterSchoolId,
      filterUserId,
      filterDates,
      schools,
    } = this.state;
    // let { url } = this.props.match;

    let dataSource = classSchedules.map((school) => ({
      key: school.id,
      ...school,
    }));

    if (filterSchoolId) {
      dataSource = dataSource.filter((d) => d.companyId === filterSchoolId);
    }
    if (filterUserId) {
      dataSource = dataSource.filter((d) => d.studentId === filterUserId);
    }
    if (filterDates) {
      dataSource = dataSource.filter((ds) => {
        const [d, m, y] = ds.date.split("/");
        const [df1, mf1, yf1] = filterDates[0].format("DD/MM/YYYY").split("/");
        const [df2, mf2, yf2] = filterDates[1].format("DD/MM/YYYY").split("/");

        const sd = dayjs(new Date(+y, +m - 1, +d, 0, 0, 0, 0));
        const fd1 = dayjs(new Date(+yf1, +mf1 - 1, +df1, 0, 0, 0, 0));
        const fd2 = dayjs(new Date(+yf2, +mf2 - 1, +df2, 0, 0, 0, 0));

        return (
          sd.isSame(dayjs(fd1)) ||
          sd.isSame(dayjs(fd2)) ||
          (sd.isAfter(dayjs(+filterDates[0])) &&
            sd.isBefore(dayjs(+filterDates[1])))
        );
      });
    }

    const columns = [
      {
        Header: "Schools",
        accessor: "school",
        Filter: SelectColumnFilter,
        // disableSortBy: true
      },
      {
        Header: "Instructor",
        accessor: "instructor",
        Filter: SelectColumnFilter,
        // disableSortBy: true
      },
      {
        Header: "Category",
        accessor: "category",
        Filter: SelectColumnFilter,
        // disableSortBy: true
      },
      {
        Header: "User",
        accessor: "student",
        // disableFilters: true
      },
 
      {
        Header: "Time",
        accessor: "time.startTime",
        Filter: TimeColumnFilter,
      },
      {
        Header: "Status",
        accessor: "classStarted",
        Filter: SelectColumnFilter,
        Cell: (val:any) =>{
            if(val.value) {
              return 'On Board'
            } else {
              return 'Yet to Board'
            }
        }
      },
      {
        Header: "Rating",
        accessor: "rating",
      },
      {
        Header: "Comments",
        accessor: "remarks",
      },
      // {
      //   Header: "Action",
      //   accessor: "action",
      //   Filter: true,
      //   Cell: () => {
      //       return <div style={{ display: 'flex', justifyContent: "space-around", cursor: "not-allowed" }}>
      //       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
      //           <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
      //           <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
      //       </svg>
      //       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
      //           <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
      //           <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
      //       </svg>
      //   </div>
      //   },
      // },
    ];
    let uniqueStudentsHash: { [key: string]: ClassScheduleInterface } = {};
    classSchedules.map((csh) => (uniqueStudentsHash[csh.studentId] = csh));
    const uniqueStudents = Object.values(uniqueStudentsHash);

    const getCurrentHours = () => {
        const d = new Date();
        const hour = d.getHours();
        return hour > 12
          ? `${hour - 12}:00 PM to ${hour + 1 - 12}:00 PM`
          : `${hour}:00 AM to ${hour + 1}:00 AM`;
      };
  
      const liveClasses = this.state?.classSchedules.filter((element)=>{
           if(element.date === GetFormattedDate() ) {
              return element
           }
      })

      
      console.log('liveClasses', liveClasses)
  
      function GetFormattedDate() {
          var todayTime = new Date();
          var month = todayTime.getMonth() + 1;
          var monthFormat = month.toString().length === 1? `0${month}`: month
          var day = todayTime.getDate();
          var dayFormat = day.toString().length === 1? `0${day}`: day
          var year = todayTime.getFullYear();
          return  dayFormat + "/" + monthFormat  + "/" + year;
      }
  
      console.log('dateee', GetFormattedDate())

    return (
        <>
        <TopBar title="Live Classes" />

        <div className='contentContainer'>
            <div className='contentTop'>
                <h5>Live Class List</h5>
            </div>

            <div className="table show">
                <TableContainer columns={columns} data={liveClasses} />
            </div>

        </div>
    </>
    );
  }
}

export default LiveClassesClone;
