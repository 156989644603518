import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Classes from './components/Pages/classes/Classes';
import Dashboard from './components/Pages/Dashboard';
import DrivingSchools from './components/Pages/drivingSchool/DrivingSchools';
import Installments from './components/Pages/installment/Installments';
import SideNav from './components/SideNav';
import Users from './components/Pages/User/Users';
import Assessments from './components/Pages/Assessments';
import BBJourney from './components/Pages/BBJourney';
import Scores from './components/Pages/scores/Scores';
import Rescheduling from './components/Pages/Rescheduling';
import Engagement from './components/Pages/engagement/Engagement';
import Purchase from './components/Pages/purchase/Purchase';
import Settings from './components/Pages/settings/Settings';
import LiveClasses from './components/Pages/liveClasses/LiveClasses';
import Sidebar from './components/Sidebar/SideBar'
import News from './components/Pages/news/News';
import Corporate from './components/Pages/Corporate';
import CorporateUser from './components/Pages/CorporateUser';
import CorporatePetroleum from './components/Pages/CorporatePetroleum';
import CorporatePetroleumUser from './components/Pages/CorporatePetroleumUser';
import RakshakLogin from './components/rakshaklogin/RakshakLogin';
import Footer from './components/Footer';
import './scss/style.css'
import firebase from './utils/Firebase';

import { RRServer } from './utils/lib';
import UserList from './components/Pages/User/UserList';
import Profile from './components/Pages/profile/Profile';
import ClassesList from './components/Pages/classes/ClassesList';
import InstallmentsList from './components/Pages/installment/InstallmentList';
import ScoresList from './components/Pages/scores/ScoresList';
import NewsClone from './components/Pages/news/NewsClone';
import EngagementClone from './components/Pages/engagement/EngagementClone';
// import StudentStats from './components/Pages/studentStats/StudentStats';
import SettingsClone from './components/Pages/settings/SettingsClone';
import StudentStats from './components/Pages/studentStats/StudentStats';
import StudentStatsClone from './components/Pages/studentStats/StudentStatsClone';
import StudentStatClone from './components/Pages/studentStats/StudentStatClone';
import LiveClassesClone from './components/Pages/liveClasses/LiveClassesClone';
import Association from './components/Pages/association/Association';
import Cluster from './components/Pages/cluster/Cluster';
import ProfileClone from './components/Pages/profile/ProfileClone';
import EngagementAdd from './components/Pages/engagement/EngagementAdd';
import SettingsAdd from './components/Pages/settings/SettingsAdd';
import AssociationsClone from './components/Pages/association/AssociationClone';
import ClustersClone from './components/Pages/cluster/ClusterClone';
import PurchaseClone from './components/Pages/purchase/PurchaseClone';
import DrivingSchoolAdd from './components/Pages/drivingSchool/DrivingSchoolAdd';
import AssociationAdd from './components/Pages/association/AssociationAdd';
import ClusterAdd from './components/Pages/cluster/ClusterAdd';
import UserListNew from './components/Pages/UserNew/UserListNew';
// import User from './components/Pages/UserOld/User';
// import SettingsClone from './components/Pages/settings/SettingsClone';


interface AppState {
  menuVisible: boolean,
  collapsed: boolean,
  isSignedIn: boolean,
  corporate: boolean,
  currentLoggedInSchool: any
}

class App extends Component {
  unregisterAuthObserver: any;
  constructor(props:any) {
    super(props);
    this.state = {
      menuVisible: false,
      collapsed: false,
      isSignedIn: false,
      corporate: false,
      currentLoggedInSchool: null
    }
  }


  componentDidMount() {
    const claimsString: any = localStorage.getItem('claims');
    const claims = JSON.parse(claimsString);
    let corporate = false;
    if (!!claims) {
      corporate = !!claims.corporate;
    }


    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user: any) => {
        if (!!user) {
          const uid = user.uid;
          RRServer.list({ table: RRServer.Tables.DRIVING_SCHOOLS, forCurrentUser: false, where: [['firebaseUserUid', '==', uid]] })
            .then((r: any) => this.setState({ isSignedIn: true, currentLoggedInSchool: r?.data.data[0], corporate }))
            .catch((r: any) => console.log(r))
          this.setState({ displayName: user.displayName, corporate })
        } else {
          this.setState({ isSignedIn: false })
        }
      }
    );
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }



  logout() {
    firebase.auth().signOut().then(function () {
      window.location.href = '/dashboard';
    }, function () {
      alert('Error Signing Out')
    })
  }

  render() {

    const path = window.location.href
       const claimsString = localStorage.getItem('claims') || '{}';
    const claims = JSON.parse(claimsString);
    const isLoggedIn = localStorage.getItem('claims');
    const isSkl =  claims.drivingSchoolId !== "*" && claims.drivingSchoolId !== "#"? true : false;
    const isSuperAdmin = claims.drivingSchoolId === "*"? true : false
    const isClusterAdmin = claims.drivingSchoolId === "#" && claims?.userType === "CLUSTER"? true : false
    const isAssociationAdmin = claims.drivingSchoolId === "#" && claims?.userType === "ASSOCIATION"? true : false

    console.log('userrrr',isSuperAdmin)

    return (
      <>
        <div className='mainWrapper'>
          {!path.endsWith('/') && <Sidebar />}
          <div className='rightContainer small'>
            <BrowserRouter>
              <Routes>
                {isLoggedIn && (isSkl || isSuperAdmin || isClusterAdmin || isAssociationAdmin) && <Route path='/dashboard' element={ <Dashboard  />} /> }
                {isLoggedIn && ( isSuperAdmin || isClusterAdmin || isAssociationAdmin) && <Route path='/drivingschools' element={<DrivingSchools />} />}
                {isLoggedIn && ( isSuperAdmin || isClusterAdmin || isAssociationAdmin) && <Route path='/drivingschools/add' element={<DrivingSchoolAdd/>} />}
                {isLoggedIn && ( isSuperAdmin || isClusterAdmin || isAssociationAdmin) && <Route path='/drivingschools/edit/:schoolId' element={<DrivingSchoolAdd/>} />}
                {isLoggedIn && (isSuperAdmin) && <Route path='/association' element={<AssociationsClone/>} />}
                {isLoggedIn && (isSuperAdmin) && <Route path='/association/add' element={<AssociationAdd/>} />}
                {isLoggedIn && (isSuperAdmin) && <Route path='/association/edit/:id' element={<AssociationAdd/>} />}
                {isLoggedIn && (isSuperAdmin) && <Route path='/cluster' element={<ClustersClone />} />}
                {isLoggedIn && (isSuperAdmin) && <Route path='/cluster/add' element={<ClusterAdd />} />}
                {isLoggedIn && (isSuperAdmin) && <Route path='/cluster/edit/:id' element={<ClusterAdd />} />}
                {isLoggedIn && (isSuperAdmin) && <Route path='/users' element={<UserList/>} />}
                {isLoggedIn && (isSkl || isSuperAdmin || isClusterAdmin || isAssociationAdmin) && <Route path='/usersOld' element={<UserListNew/>} />}
                {isLoggedIn && (isSkl || isSuperAdmin || isClusterAdmin || isAssociationAdmin) && <Route path='/classes' element={<ClassesList />} />}
                {isLoggedIn && (isSkl || isSuperAdmin || isClusterAdmin || isAssociationAdmin) && <Route path='/liveClasses' element={<LiveClassesClone />} />}
                {isLoggedIn && (isSkl || isSuperAdmin || isClusterAdmin) && <Route path='/installments' element={<InstallmentsList />} />}
                {isLoggedIn && (isSkl || isSuperAdmin || isClusterAdmin || isAssociationAdmin) && <Route path='/scores' element={<ScoresList />} />}
                {isLoggedIn && (isSkl || isSuperAdmin || isClusterAdmin || isAssociationAdmin) && <Route  path='/studentStats' element={<StudentStatsClone />} />}
                {isLoggedIn && (isSuperAdmin) && <Route path='/bbjourney' element={<BBJourney />} />}
                {isLoggedIn && (isSuperAdmin) && <Route path='/assessments' element={<Assessments />} />}
                {isLoggedIn && (isSkl || isSuperAdmin || isClusterAdmin || isAssociationAdmin) && <Route path='/rescheduling' element={<Rescheduling />} />}
                {isLoggedIn && (isSkl || isSuperAdmin ) && <Route path='/engagement' element={<EngagementClone />} />}
                {isLoggedIn && (isSuperAdmin || isSkl) && <Route path='/purchase' element={<PurchaseClone />} />}
                {isLoggedIn && (isSkl || isSuperAdmin || isAssociationAdmin) && <Route path='/news' element={<NewsClone />} />}
                {isLoggedIn && (isSuperAdmin) && <Route path='/corporate' element={<Corporate />} />}
                {isLoggedIn && (isSuperAdmin) && <Route path='/corporateUser' element={<CorporateUser />} />}
                {isLoggedIn && (isSuperAdmin) && <Route path='/corporatePetroleum' element={<CorporatePetroleum />} />}
                {isLoggedIn && (isSuperAdmin) && <Route path='/corporatePetroleumUser' element={<CorporatePetroleumUser />} />}
                {isLoggedIn && ( isSuperAdmin || isClusterAdmin) && <Route path='/settings' element={<SettingsClone/>} />}
                {isLoggedIn && (isSkl || isSuperAdmin || isClusterAdmin) && <Route path='/profile' element={<ProfileClone />} />}
                <Route path='/' element={<RakshakLogin />} />
              </Routes>
            </BrowserRouter>
          </div>
        </div>
        {/* {!path.endsWith('/login') && path.endsWith('/') && <Footer />} */}
      </>
    );
  }
}

export default App;

