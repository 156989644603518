import React, { Component } from "react";
import styles from "../../../FormComponent/FormComponent.module.css";
import Slider from "react-slick";
import moment from "moment";

interface AddUserFormState {
  monday: any;
  tuesday: any;
  wednesday: any;
  thursday: any;
  friday: any;
  saturday: any;
  sunday: any;

  studentClasses: any;
  isAllClasses: number;

  isClasses: boolean;

  instructor: string;
  selectDate: string;
  noOfClasses: any;
  noOfInstallments: string;
  installmentDates: any;
}

interface AddUserFormProps {
  instructors: any;
  handleSlots: any;
}

class AddUserForm extends Component<AddUserFormProps, AddUserFormState> {
  state: AddUserFormState = {
    monday: [
      { startTime: "10:00 am", endTime: "12:00 am", slots: 4 },
      { startTime: "1:00 pm", endTime: "2:00 am", slots: 4 },
      { startTime: "10:00 am", endTime: "12:00 am", slots: 4 },
      { startTime: "1:00 pm", endTime: "2:00 pm", slots: 4 },
    ],
    tuesday: [
      { startTime: "11:00 am", endTime: "12:00 am", slots: 4 },
      { startTime: "10:00 am", endTime: "12:00 am", slots: 4 },
      { startTime: "1:00 pm", endTime: "2:00 pm", slots: 4 },
      { startTime: "11:00 am", endTime: "12:00 am", slots: 4 },
      { startTime: "10:00 am", endTime: "12:00 am", slots: 4 },
      { startTime: "1:00 pm", endTime: "2:00 pm", slots: 4 },
    ],
    wednesday: [
      { startTime: "09:00 am", endTime: "11:00 am", slots: 4 },
      { startTime: "10:00 am", endTime: "12:00 am", slots: 4 },
      { startTime: "10:00 am", endTime: "12:00 am", slots: 4 },
      { startTime: "1:00 pm", endTime: "2:00 pm", slots: 4 },
    ],
    thursday: [
      { startTime: "10:00 am", endTime: "12:00 am", slots: 4 },
      { startTime: "10:00 am", endTime: "12:00 am", slots: 4 },
      { startTime: "1:00 pm", endTime: "2:00 pm", slots: 4 },
      { startTime: "11:00 am", endTime: "12:00 am", slots: 4 },
      { startTime: "10:00 am", endTime: "12:00 am", slots: 4 },
      { startTime: "1:00 pm", endTime: "2:00 pm", slots: 4 },
    ],
    friday: [
      { startTime: "10:00 am", endTime: "12:00 am", slots: 4 },
      { startTime: "10:00 am", endTime: "12:00 am", slots: 4 },
      { startTime: "10:00 am", endTime: "12:00 am", slots: 4 },
      { startTime: "1:00 pm", endTime: "2:00 pm", slots: 4 },
    ],
    saturday: [
      { startTime: "10:00 am", endTime: "12:00 am", slots: 4 },
      { startTime: "10:00 am", endTime: "12:00 am", slots: 4 },
      { startTime: "1:00 pm", endTime: "2:00 pm", slots: 4 },
      { startTime: "11:00 am", endTime: "12:00 am", slots: 4 },
      { startTime: "10:00 am", endTime: "12:00 am", slots: 4 },
      { startTime: "1:00 pm", endTime: "2:00 pm", slots: 4 },
    ],
    sunday: [
      { startTime: "10:00 am", endTime: "12:00 am", slots: 4 },
      ,
      { startTime: "10:00 am", endTime: "12:00 am", slots: 4 },
      { startTime: "10:00 am", endTime: "12:00 am", slots: 4 },
      { startTime: "1:00 pm", endTime: "2:00 pm", slots: 4 },
    ],

    isClasses: false,

    studentClasses: {
      Mon: [],
      Tue: [],
      Wed: [],
      Thu: [],
      Fri: [],
      Sat: [],
      Sun: [],
    },
    isAllClasses: 0,

    instructor: "",
    selectDate: "",
    noOfClasses: null,
    noOfInstallments: "",
    installmentDates: [],
  };

  handleSlot = (e: any, timing: any) => {
    e.preventDefault();

    console.log("eee", e);
    console.log("timee", timing);

    if (e.target.classList.contains("selected")) {
      this.setState({ isAllClasses: this.state.isAllClasses - 1 });

      if (e.target.name === "monday") {
        this.setState({
          studentClasses: {
            ...this.state.studentClasses,
            Mon: [
              ...this.state.studentClasses.Mon.filter(
                (val: any) => val.startTime !== timing.startTime
              ),
            ],
          },
        });
      } else if (e.target.name === "tuesday") {
        this.setState({
          studentClasses: {
            ...this.state.studentClasses,
            Tue: [
              ...this.state.studentClasses.Tue.filter(
                (val: any) => val.startTime !== timing.startTime
              ),
            ],
          },
        });
      } else if (e.target.name === "wednesday") {
        this.setState({
          studentClasses: {
            ...this.state.studentClasses,
            Wed: [
              ...this.state.studentClasses.Wed.filter(
                (val: any) => val.startTime !== timing.startTime
              ),
            ],
          },
        });
      } else if (e.target.name === "thursday") {
        this.setState({
          studentClasses: {
            ...this.state.studentClasses,
            Thu: [
              ...this.state.studentClasses.Thu.filter(
                (val: any) => val.startTime !== timing.startTime
              ),
            ],
          },
        });
      } else if (e.target.name === "friday") {
        this.setState({
          studentClasses: {
            ...this.state.studentClasses,
            Fri: [
              ...this.state.studentClasses.Fri.filter(
                (val: any) => val.startTime !== timing.startTime
              ),
            ],
          },
        });
      } else if (e.target.name === "saturday") {
        this.setState({
          studentClasses: {
            ...this.state.studentClasses,
            Sat: [
              ...this.state.studentClasses.Sat.filter(
                (val: any) => val.startTime !== timing.startTime
              ),
            ],
          },
        });
      } else if (e.target.name === "sunday") {
        this.setState({
          studentClasses: {
            ...this.state.studentClasses,
            Sun: [
              ...this.state.studentClasses.Sun.filter(
                (val: any) => val.startTime !== timing.startTime
              ),
            ],
          },
        });
      }

      e.target.firstElementChild.innerText =
        parseInt(e.target.firstElementChild.innerText) + 1;
      e.target.classList.remove("selected");
    } else {
      if (
        e.target.name === "monday" &&
        this.state.noOfClasses > this.state.isAllClasses
      ) {
        this.setState({
          studentClasses: {
            ...this.state.studentClasses,
            Mon: [...this.state.studentClasses.Mon, timing],
          },
        });
        this.setState({ isAllClasses: this.state.isAllClasses + 1 });
      } else if (
        e.target.name === "tuesday" &&
        this.state.noOfClasses > this.state.isAllClasses
      ) {
        this.setState({
          studentClasses: {
            ...this.state.studentClasses,
            Tue: [...this.state.studentClasses.Tue, timing],
          },
        });
        this.setState({ isAllClasses: this.state.isAllClasses + 1 });
      } else if (
        e.target.name === "wednesday" &&
        this.state.noOfClasses > this.state.isAllClasses
      ) {
        this.setState({
          studentClasses: {
            ...this.state.studentClasses,
            Wed: [...this.state.studentClasses.Wed, timing],
          },
        });
        this.setState({ isAllClasses: this.state.isAllClasses + 1 });
      } else if (
        e.target.name === "thursday" &&
        this.state.noOfClasses > this.state.isAllClasses
      ) {
        this.setState({
          studentClasses: {
            ...this.state.studentClasses,
            Thu: [...this.state.studentClasses.Thu, timing],
          },
        });
        this.setState({ isAllClasses: this.state.isAllClasses + 1 });
      } else if (
        e.target.name === "friday" &&
        this.state.noOfClasses > this.state.isAllClasses
      ) {
        this.setState({
          studentClasses: {
            ...this.state.studentClasses,
            Fri: [...this.state.studentClasses.Fri, timing],
          },
        });
        this.setState({ isAllClasses: this.state.isAllClasses + 1 });
      } else if (
        e.target.name === "saturday" &&
        this.state.noOfClasses > this.state.isAllClasses
      ) {
        this.setState({
          studentClasses: {
            ...this.state.studentClasses,
            Sat: [...this.state.studentClasses.Sat, timing],
          },
        });
        this.setState({ isAllClasses: this.state.isAllClasses + 1 });
      } else if (
        e.target.name === "sunday" &&
        this.state.noOfClasses > this.state.isAllClasses
      ) {
        this.setState({
          studentClasses: {
            ...this.state.studentClasses,
            Sun: [...this.state.studentClasses.Sun, timing],
          },
        });
        this.setState({ isAllClasses: this.state.isAllClasses + 1 });
      }

      if (this.state.noOfClasses > this.state.isAllClasses) {
        e.target.firstElementChild.innerText =
          e.target.firstElementChild.innerText - 1;
        e.target.classList.add("selected");
      }
    }
  };

  setSlots = (instructorId: string) => {
    const insSlots = this.props.instructors.filter(
      (ins: any) => ins.id === instructorId
    );
    console.log("insss", insSlots[0].slots);
    this.setState({
      monday: [...insSlots[0].slots.Mon],
      tuesday: [...insSlots[0].slots.Tue],
      wednesday: [...insSlots[0].slots.Wed],
      thursday: [...insSlots[0].slots.Thu],
      friday: [...insSlots[0].slots.Fri],
      saturday: [...insSlots[0].slots.Sat],
      sunday: [...insSlots[0].slots.Sun],
    });
  };

  handleIns = (e: any) => {
    this.setState({ instructor: e.target.value });
    this.setSlots(e.target.value);
  };

  handleSubmit = (e: any) => {
    e.preventDefault();
    // this.props.handleSlots({
    //   startDate: this.state.selectDate,
    //   slots: this.state.studentClasses,
    //   installmentDates: this.state.installmentDates,
    //   noOfClasses: this.state.noOfClasses,
    //   instructorId: this.state.instructor,
    // });

    const {
      selectDate,
      studentClasses,
      installmentDates,
      noOfClasses,
      instructor,
    } = this.state;
    console.log("slotts", {
      startDate: selectDate,
      slots: studentClasses,
      installmentDates: installmentDates,
      noOfClasses: noOfClasses,
      instructorId: instructor,
    });
    this.props.handleSlots({
      startDate: selectDate,
      slots: studentClasses,
      installmentDates: installmentDates,
      noOfClasses: noOfClasses,
      instructorId: instructor,
    });
  };

  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      adaptiveHeight: true,
    };

    const inputs = [];

    for (let i = 1; i <= parseInt(this.state.noOfInstallments); i++) {
      inputs.push(
        <div className={`mb-3 form-floating  inline-block col-md-12 col-sm-12`}>
          <input
            className={`form-control`}
            type={"date"}
            name={`InstallmentDate-${i}`}
            onChange={(e) =>
              this.setState({
                installmentDates: [
                  ...this.state.installmentDates,
                  {
                    installment: e.target.name,
                    value: e.target.value,
                    status: 0,
                    paidOn: -1,
                  },
                ],
              })
            }
            placeholder="select Date"
            maxLength={200}
            id="selectDate"
            required={true}
          />
          <label
            htmlFor="selectDate"
            className={`px-4`}
          >{`Select Date for installment ${i}`}</label>
        </div>
      );
    }

    const getKey : any= (obj : any) => {
      var arr = [];
      for (var x of Object.keys(obj)){
        arr.push(x);
      }
      return arr;
    }
    

    var dates: any = [];
    var i = 0;
    do {
      i += 1;
      

    } while (i > this.state.noOfClasses);

    return (
      <div className="formTwoWrapper">
        {console.log(    console.log(getKey(this.state.studentClasses)))}
        <div className="formInputs formInputsA" style={{ height: "400px" }}>
          <div className="formWrapper user">
            <div className={styles.formWrapper}>
              <form className="container needs-validation">
                <div className="row">
                  <div
                    className={`col-md-12   col-sm-12 mb-2 form-floating d-inline-block`}
                  >
                    <select
                      className={`form-select form-control`}
                      value={this.state.instructor}
                      name="instructor"
                      onChange={(e) => this.handleIns(e)}
                      id="instructor"
                      aria-label="Floating label select example"
                      required={true}
                    >
                      <option value="">Select Value from dropdown...</option>
                      {this.props.instructors.map((ins: any) => {
                        return (
                          <option key={ins.id} value={ins.id}>
                            {ins.name}
                          </option>
                        );
                      })}
                    </select>
                    <label htmlFor="instructor" className={`px-4`}>
                      Choose Instructor
                    </label>
                  </div>
                  <div
                    className={`mb-3 form-floating  inline-block col-md-12 col-sm-12`}
                  >
                    <input
                      className={`form-control`}
                      type="date"
                      name={"selectDate"}
                      value={this.state.selectDate}
                      onChange={(e) =>
                        this.setState({ selectDate: e.target.value })
                      }
                      placeholder="select Date"
                      maxLength={200}
                      id="selectDate"
                      required={true}
                    />
                    <label htmlFor="selectDate" className={`px-4`}>
                      'Select Date
                    </label>
                  </div>

                  <div
                    className={`mb-3 form-floating  inline-block col-md-12 col-sm-12`}
                  >
                    <input
                      className={`form-control`}
                      type={"number"}
                      name={"noOfClasses"}
                      value={this.state.noOfClasses}
                      onChange={(e) =>
                        this.setState({
                          noOfClasses: parseInt(e.target.value),
                          isClasses: true,
                        })
                      }
                      placeholder="select No of Classes"
                      maxLength={200}
                      id="noOfClasses"
                      required={true}
                    />
                    <label htmlFor="noOfClasses" className={`px-4`}>
                      No of Classes
                    </label>
                  </div>

                  <div
                    className={`mb-3 form-floating  inline-block col-md-12 col-sm-12`}
                  >
                    <input
                      className={`form-control`}
                      type={"number"}
                      name={"noOfInstallments"}
                      value={this.state.noOfInstallments}
                      onChange={(e) =>
                        this.setState({ noOfInstallments: e.target.value })
                      }
                      placeholder="noOfInstallments"
                      maxLength={200}
                      id="noOfInstallments"
                      required={true}
                    />
                    <label htmlFor="noOfInstallments" className={`px-4`}>
                      No of Installments
                    </label>
                  </div>

                  {inputs.map((input) => {
                    return input;
                  })}
                </div>
                <div
                  className="text-right"
                  id="btns btnsA"
                  style={{ position: "absolute", right: 0, bottom: "-4rem" }}
                >
                  {this.state.instructor &&
                    this.state.selectDate &&
                    this.state.noOfClasses &&
                    this.state.noOfInstallments && (
                      <button
                        className={`${styles.btn} btn btn-primary`}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#userPreview"
                      >
                        Preview
                      </button>
                    )}
                  <button
                    className={styles.btn}
                    onClick={(e) => {
                      this.handleSubmit(e);
                    }}
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="cardPreview user">
            {/* <p>Select Class</p> */}
            <div className="instructorTable">
              <div
                className={`${
                  this.state.isClasses
                    ? "instructorView show"
                    : "instructorView hide"
                }`}
              >
                <div className="tableFormat">
                  <div className="row">
                    <div className="title">
                      <p>Monday</p>
                    </div>
                    <Slider {...settings}>
                      {this.state.monday.map((timing: any) => {
                        return (
                          <div>
                            <button
                              name="monday"
                              className="timingSlots"
                              onClick={(e: any) => this.handleSlot(e, timing)}
                            >
                              {timing.startTime} - {timing.endTime}{" "}
                              <span>{timing.slots}</span>
                            </button>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>

                  <div className="row">
                    <div className="title">
                      <p>Tuesday</p>
                    </div>
                    <Slider {...settings}>
                      {this.state.tuesday.map((timing: any) => {
                        return (
                          <div>
                            <button
                              name="tuesday"
                              className="timingSlots"
                              onClick={(e: any) => this.handleSlot(e, timing)}
                            >
                              {timing.startTime} - {timing.endTime}{" "}
                              <span>{timing.slots}</span>
                            </button>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>

                  <div className="row">
                    <div className="title">
                      <p>Wednesday</p>
                    </div>
                    <Slider {...settings}>
                      {this.state.wednesday.map((timing: any) => {
                        return (
                          <div>
                            <button
                              name="wednesday"
                              className="timingSlots"
                              onClick={(e: any) => this.handleSlot(e, timing)}
                            >
                              {timing.startTime} - {timing.endTime}{" "}
                              <span>{timing.slots}</span>
                            </button>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>

                  <div className="row">
                    <div className="title">
                      <p>Thursday</p>
                    </div>
                    <Slider {...settings}>
                      {this.state.thursday.map((timing: any) => {
                        return (
                          <div>
                            <button
                              name="thursday"
                              className="timingSlots"
                              onClick={(e: any) => this.handleSlot(e, timing)}
                            >
                              {timing.startTime} - {timing.endTime}{" "}
                              <span>{timing.slots}</span>
                            </button>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>

                  <div className="row">
                    <div className="title">
                      <p>Friday</p>
                    </div>
                    <Slider {...settings}>
                      {this.state.friday.map((timing: any) => {
                        return (
                          <div>
                            <button
                              name="friday"
                              className="timingSlots"
                              onClick={(e: any) => this.handleSlot(e, timing)}
                            >
                              {timing.startTime} - {timing.endTime}{" "}
                              <span>{timing.slots}</span>
                            </button>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>

                  <div className="row">
                    <div className="title">
                      <p>Saturday</p>
                    </div>
                    <Slider {...settings}>
                      {this.state.saturday.map((timing: any) => {
                        return (
                          <div>
                            <button
                              name="saturday"
                              className="timingSlots"
                              onClick={(e: any) => this.handleSlot(e, timing)}
                            >
                              {timing.startTime} - {timing.endTime}{" "}
                              <span>{timing.slots}</span>
                            </button>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>

                  <div className="row">
                    <div className="title">
                      <p>Sunday</p>
                    </div>
                    <Slider {...settings}>
                      {this.state.sunday.map((timing: any) => {
                        return (
                          <div>
                            <button
                              name="sunday"
                              className="timingSlots"
                              onClick={(e: any) => this.handleSlot(e, timing)}
                            >
                              {timing.startTime} - {timing.endTime}{" "}
                              <span>{timing.slots}</span>
                            </button>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="userPreview"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" style={{ zIndex: "100" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="userPreview">
                  Preview
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div
                className="modal-body"
                style={{ maxHeight: "70vh", overflowY: "scroll" }}
              >
                <div
                  className="previewContainer"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    className="classesScheduled"
                    style={{ width: "50%", padding: "10px" }}
                  >
                    <h2>Your Classes </h2>

                    {/* startDate = moment(startDate).add(1, "days").format("YYYY-MM-DD"); */}
                    {this.state.noOfClasses &&
                      [...Array(this.state.noOfClasses)].map((e, idx) => {
                        return (
                          <div className="classCard">
                            <span className="number">{idx + 1}</span>
                            <div className="info">
                              <p className="date">
                                {moment(this.state.selectDate)
                                  .add(idx, "days")
                                  .format("DD-MM-YYYY")}
                              </p>
                              {moment(this.state.selectDate)
                                .add(idx, "days")
                                .format("dddd") === "Monday" &&
                                this.state.studentClasses.Mon.map(
                                  (time: any, idx: any) => {
                                    return (
                                      <p className="time">
                                        {" "}
                                        {time.startTime}-{time.endTime}{" "}
                                      </p>
                                    );
                                  }
                                )}
                              {moment(this.state.selectDate)
                                .add(idx, "days")
                                .format("dddd") === "Tuesday" &&
                                this.state.studentClasses.Tue.map(
                                  (time: any, idx: any) => {
                                    return (
                                      <p className="time">
                                        {" "}
                                        {time.startTime}- {time.endTime}{" "}
                                      </p>
                                    );
                                  }
                                )}
                              {moment(this.state.selectDate)
                                .add(idx, "days")
                                .format("dddd") === "Wednesday" &&
                                this.state.studentClasses.Wed.map(
                                  (time: any, idx: any) => {
                                    return (
                                      <p className="time">
                                        {" "}
                                        {time.startTime} - {time.endTime}{" "}
                                      </p>
                                    );
                                  }
                                )}
                              {moment(this.state.selectDate)
                                .add(idx, "days")
                                .format("dddd") === "Thursday" &&
                                this.state.studentClasses.Thu.map(
                                  (time: any, idx: any) => {
                                    return (
                                      <p className="time">
                                        {" "}
                                        {time.startTime}- {time.endTime}{" "}
                                      </p>
                                    );
                                  }
                                )}
                              {moment(this.state.selectDate)
                                .add(idx, "days")
                                .format("dddd") === "Friday" &&
                                this.state.studentClasses.Fri.map(
                                  (time: any, idx: any) => {
                                    return (
                                      <p className="time">
                                        {" "}
                                        {time.startTime}- {time.endTime}{" "}
                                      </p>
                                    );
                                  }
                                )}
                              {moment(this.state.selectDate)
                                .add(idx, "days")
                                .format("dddd") === "Saturday" &&
                                this.state.studentClasses.Sat.map(
                                  (time: any, idx: any) => {
                                    return (
                                      <p className="time">
                                        {" "}
                                        {time.startTime}- {time.endTime}{" "}
                                      </p>
                                    );
                                  }
                                )}
                              {moment(this.state.selectDate)
                                .add(idx, "days")
                                .format("dddd") === "Sunday" &&
                                this.state.studentClasses.Sun.map(
                                  (time: any, idx: any) => {
                                    return (
                                      <p className="time">
                                        {" "}
                                        {time.startTime}- {time.endTime}{" "}
                                      </p>
                                    );
                                  }
                                )}
                            </div>
                            <p className="day">
                              {moment(this.state.selectDate)
                                .add(idx, "days")
                                .format("dddd")}
                            </p>
                          </div>
                        );
                      })}

                    {/* <div className="classCard">
                                                <span className="number">2</span>
                                                <div className="info">
                                                    <p className='date'>01/01/2022</p>
                                                    <p className="time">10:00 am - 12:00 pm </p>
                                                </div>
                                                <p className="day">Tuesday</p>
                                            </div>

                                            <div className="classCard">
                                                <span className="number">3</span>
                                                <div className="info">
                                                    <p className='date'>02/01/2022</p>
                                                    <p className="time">10:00 am - 12:00 pm </p>
                                                </div>
                                                <p className="day">Wednesday</p>
                                            </div>

                                            <div className="classCard">
                                                <span className="number">2</span>
                                                <div className="info">
                                                    <p className='date'>01/01/2022</p>
                                                    <p className="time">10:00 am - 12:00 pm </p>
                                                </div>
                                                <p className="day">Tuesday</p>
                                            </div>

                                            <div className="classCard">
                                                <span className="number">3</span>
                                                <div className="info">
                                                    <p className='date'>02/01/2022</p>
                                                    <p className="time">10:00 am - 12:00 pm </p>
                                                </div>
                                                <p className="day">Wednesday</p>
                                            </div>

                                            <div className="classCard">
                                                <span className="number">2</span>
                                                <div className="info">
                                                    <p className='date'>01/01/2022</p>
                                                    <p className="time">10:00 am - 12:00 pm </p>
                                                </div>
                                                <p className="day">Tuesday</p>
                                            </div>

                                            <div className="classCard">
                                                <span className="number">3</span>
                                                <div className="info">
                                                    <p className='date'>02/01/2022</p>
                                                    <p className="time">10:00 am - 12:00 pm </p>
                                                </div>
                                                <p className="day">Wednesday</p>
                                            </div>

                                            <div className="classCard">
                                                <span className="number">2</span>
                                                <div className="info">
                                                    <p className='date'>01/01/2022</p>
                                                    <p className="time">10:00 am - 12:00 pm </p>
                                                </div>
                                                <p className="day">Tuesday</p>
                                            </div>

                                            <div className="classCard">
                                                <span className="number">3</span>
                                                <div className="info">
                                                    <p className='date'>02/01/2022</p>
                                                    <p className="time">10:00 am - 12:00 pm </p>
                                                </div>
                                                <p className="day">Wednesday</p>
                                            </div>

                                            <div className="classCard">
                                                <span className="number">2</span>
                                                <div className="info">
                                                    <p className='date'>01/01/2022</p>
                                                    <p className="time">10:00 am - 12:00 pm </p>
                                                </div>
                                                <p className="day">Tuesday</p>
                                            </div>

                                            <div className="classCard">
                                                <span className="number">3</span>
                                                <div className="info">
                                                    <p className='date'>02/01/2022</p>
                                                    <p className="time">10:00 am - 12:00 pm </p>
                                                </div>
                                                <p className="day">Wednesday</p>
                                            </div>

                                            <div className="classCard">
                                                <span className="number">2</span>
                                                <div className="info">
                                                    <p className='date'>01/01/2022</p>
                                                    <p className="time">10:00 am - 12:00 pm </p>
                                                </div>
                                                <p className="day">Tuesday</p>
                                            </div>

                                            <div className="classCard">
                                                <span className="number">3</span>
                                                <div className="info">
                                                    <p className='date'>02/01/2022</p>
                                                    <p className="time">10:00 am - 12:00 pm </p>
                                                </div>
                                                <p className="day">Wednesday</p>
                                            </div> */}
                  </div>
                  <div
                    className="installmentPreview"
                    style={{ width: "50%", padding: "10px" }}
                  >
                    <h2>Installments details:</h2>

                    {this.state.installmentDates.map((date: any, idx: any) => {
                      return (
                        <div className="classCard">
                          <span className="number">{idx + 1}</span>
                          <div className="info">
                            <p className="date">{date.value}</p>
                            {/* <p className="time">10:00 am - 12:00 pm </p> */}
                          </div>
                          <p className="day">{date.title}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddUserForm;
