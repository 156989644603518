import React, { Component } from 'react';
import { SelectColumnFilter, DateColumnFilter, Filter, TimeColumnFilter } from '../TableComp/Filter';
import TableContainer from '../TableComp/TableContainer';
import { callOnServer, RRServer } from "../../utils/lib";

// import TableContainer from '../TableComp/TableContainer';
import { CSVLink } from "react-csv";
import TopBar from '../TopBar';
import { AnyMxRecord } from 'dns';


// const stylesBtn = () => {
//     return {
//         color: "white",
//         width:"100px",
//         height:"30px",
//         backgroundColor:"blue"
//     }
// }


const headers = [
    { label: "Schools", key: "school" },
    { label: "Instructor", key: "instructor" },
    { label: "User", key: "user" },
    { label: "No Of Installments", key: "noOfInstallments" },
    { label: "Status", key: "status" },
    { label: "Payment Start Date", key: "proposedDate" },
    { label: "Payment End Date", key: "proposedTime" },
    { label: "Action", key: "action" }
];
const columns = [
    {
        Header: 'Schools',
        accessor: 'school',
        Filter: SelectColumnFilter,
        key: 'school',
        label: 'school'
        // disableSortBy: true
    },
    {
        Header: 'Instructor',
        accessor: 'instructor',
        Filter: SelectColumnFilter,
        key: 'instructor',
        label: 'instructor'
        // disableSortBy: true
    },
    {
        Header: 'User',
        accessor: 'user',
        Filter: SelectColumnFilter,
        key: 'user',
        label: 'user'
        // disableSortBy: true
    },
    {
        Header: 'Scheduled Date',
        accessor: 'scheduledDate',
        key: 'scheduledDate',
        label: 'scheduledDate',
        Filter: DateColumnFilter
    },
    {
        Header: 'Scheduled Time',
        accessor: 'scheduledTime',
        key: 'scheduledTime',
        label: 'scheduledTime',
        Filter: TimeColumnFilter
    },
    {
        Header: 'Proposed Date',
        accessor: 'proposedDate',
        key: 'proposedDate',
        label: 'proposedDate',
        Filter: DateColumnFilter
    },
    {
        Header: 'Proposed Time',
        accessor: 'proposedTime',
        key: 'proposedTime',
        label: 'proposedTime',
        Filter: TimeColumnFilter
    },
    {
        Header: 'Action',
        accessor: 'action',
        key: 'action',
        label: 'action',
        Filter: true
    },
]

const Data : any = [
    // {
    //     isClickable: true, id: '1', school: 'Raja Driving School', user: 'one', instructor: 'AK', proposedDate: '2021-12-01', proposedTime: '05:40', scheduledDate: '2021-12-01', scheduledTime: '12:05', action: <div style={{ display: 'flex', justifyContent: "space-around", cursor: "not-allowed" }}>
    //         <a type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop" style={{ textDecoration: "underline", color: "blue", marginRight: "5px" }}>Reschedule</a>
    //     </div>
    // },
    // {
    //     isClickable: true, id: '1', school: 'ABC Driving School', user: 'one', instructor: 'FJ', proposedDate: '2021-12-02', proposedTime: '07:40', scheduledDate: '2021-12-01', scheduledTime: '07:05', action: <div style={{ display: 'flex', justifyContent: "space-around", cursor: "not-allowed" }}>
    //         <a type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop" style={{ textDecoration: "underline", color: "blue" }}>Reschedule</a>
    //     </div>
    // },
    // {
    //     isClickable: true, id: '1', school: 'JK Driving School', user: 'one', instructor: 'AK', proposedDate: '2021-12-04', proposedTime: '05:59', scheduledDate: '2021-12-01', scheduledTime: '05:50', action: <div style={{ display: 'flex', justifyContent: "space-around", cursor: "not-allowed" }}>
    //         <a type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop" style={{ textDecoration: "underline", color: "blue" }}>Reschedule</a>
    //     </div>
    // }, /* f */
    // {
    //     isClickable: true, id: '1', school: 'ABC Driving School', user: 'one', instructor: 'AK', proposedDate: '2022-01-03', proposedTime: '08:20', scheduledDate: '2021-12-01', scheduledTime: '08:20', action: <div style={{ display: 'flex', justifyContent: "space-around", cursor: "not-allowed" }}>
    //         <a type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop" style={{ textDecoration: "underline", color: "blue" }}>Reschedule</a>
    //     </div>
    // },
    // {
    //     isClickable: true, id: '1', school: 'Raja Driving School', user: 'one', instructor: 'Raju', proposedDate: '2022-01-10', proposedTime: '10:30', scheduledDate: '2021-12-01', scheduledTime: '10:30', action: <div style={{ display: 'flex', justifyContent: "space-around", cursor: "not-allowed" }}>
    //         <a type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop" style={{ textDecoration: "underline", color: "blue" }}>Reschedule</a>
    //     </div>
    // },

]

interface ReschedulingState {
    isAvailable: boolean,
    isSearch: boolean,
    isReschedule: boolean,
    reschedulingData: any[]
}


class Rescheduling extends Component<{}, ReschedulingState> {

    state: ReschedulingState = {
        isAvailable: false,
        isSearch: false,
        isReschedule: false,
        reschedulingData: []
    }

    // componentDidMount() {

    //         RRServer.get({ table: 'rescheduling_request' ,id : '' }).then((result: any) => {
    //             console.log('reschedulingData gett',result)
    //             let Data = [result?.data?.data];
    //             this.setState({ reschedulingData: [...Data] })
    //         });
        
        
    // }

    render() {
        return (
            <>
                                <TopBar title="Rescheduling"/>

                <div className='contentContainer'>
                    <div className='contentTop'>
                        <h5>Rescheduling List</h5>

                        <div className='tableBtn' style={{ marginLeft: "auto" }}>
                            <CSVLink data={Data} headers={headers} className='btn btn-primary'/* style={stylesBtn()} */>Export CSV</CSVLink>
                        </div>
                    </div>
                    <div className="table show">
                        <TableContainer columns={columns} data={Data} />
                    </div>
                    {/* Modal Starts */}
                    <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel">Reschedule Time and Date</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <p>School : <span>ABC Driving School</span></p>
                                    <div className="row">
                                        <div className="mb-3 form-floating  inline-block col-md-6 col-sm-6">
                                            <input className="form-control" type="text" name="school" placeholder="School" maxLength={200} id="school" value={'Instructor name'} readOnly />
                                            <label htmlFor="school" className="px-4">Instructor</label>
                                        </div>

                                        <div className="mb-3 form-floating  inline-block col-md-6 col-sm-6">
                                            <input className="form-control" type="text" name="name" placeholder="User" maxLength={200} id="user" value={`User`} readOnly />
                                            <label htmlFor="user" className="px-4">User</label>
                                        </div>

                                        <div className="mb-3 form-floating  inline-block col-md-6 col-sm-6">
                                            <input className="form-control" type="date" name="scheduledDate" placeholder="Scheduled Date" maxLength={200} id="scheduledDate" value={`2022-01-22`} readOnly />
                                            <label htmlFor="scheduledDate" className="px-4">Scheduled Date</label>
                                        </div>

                                        <div className="mb-3 form-floating  inline-block col-md-6 col-sm-6">
                                            <input className="form-control" type="time" name="scheduledTime" placeholder="Scheduled Time" maxLength={200} id="scheduledTime" value={`18:00`} readOnly />
                                            <label htmlFor="scheduledTime" className="px-4">Scheduled Time</label>
                                        </div>

                                        <div className="mb-3 form-floating  inline-block col-md-6 col-sm-6">
                                            <input className="form-control" type="date" name="availableTime" placeholder="Available Time" maxLength={200} id="availableTime" />
                                            <label htmlFor="availableTime" className="px-4">Rescheduled Date</label>
                                        </div>

                                        <div className={`col-md-6   col-sm-12 mb-2 form-floating d-inline-block`}>
                                            <select className={`form-select form-control`}  name='userType'  id='userType' aria-label="Floating label select example" required={true}>
                                                <option value={'9 AM - 10 AM'} selected>9 AM - 10 AM</option>
                                                <option value={'10 AM - 12AM'}>10 AM - 12AM</option>
                                                <option value={'1PM - 2PM'}>1PM - 2PM</option>
                                                <option value={'3PM - 4PM'}>3PM - 4PM</option>
                                            </select>
                                            <label htmlFor='userType' className={`px-4`}>Rescheduled Time</label>
                                        </div>
{/* 
                                        <div className="mb-3 form-floating  inline-block col-md-6 col-sm-6">
                                            <input className="form-control" type="time" name="availableDate" placeholder="Available Date" maxLength={200} id="availableDate" />
                                            <label htmlFor="availableDate" className="px-4">Rescheduled Time</label>
                                        </div> */}


                                        <p className={this.state.isAvailable ? 'available' : 'notAvailable'}>{this.state.isAvailable ? 'Available' : 'Not Available'}</p>

                                        <div className="buttonWrapper" style={{ display: "flex", justifyContent: "space-around" }}>
                                            <button type="button" className="btn btn-primary" disabled={!this.state.isAvailable}>Accept</button>
                                            <button type="button" className="btn btn-primary" disabled={this.state.isAvailable} onClick={() => this.setState({ isReschedule: true })}>Reschedule</button>
                                        </div>


                                        {!this.state.isAvailable && this.state.isReschedule && <><div className="mb-3 form-floating  inline-block col-md-6 col-sm-6">
                                            <input className="form-control" type="date" name="availableTime" min={new Date().toISOString().split('T')[0]}  placeholder="Available Time" maxLength={200} id="availableTime" />
                                            <label htmlFor="availableTime" className="px-4">Available Date</label>
                                        </div>
                                        <div className={`col-md-6   col-sm-12 mb-2 form-floating d-inline-block`}>
                                            <select className={`form-select form-control`}  name='userType'  id='userType' aria-label="Floating label select example" required={true}>
                                                <option value={'9 AM - 10 AM'} selected>9 AM - 10 AM</option>
                                                <option value={'10 AM - 12AM'}>10 AM - 12AM</option>
                                                <option value={'1PM - 2PM'}>1PM - 2PM</option>
                                                <option value={'3PM - 4PM'}>3PM - 4PM</option>
                                            </select>
                                            <label htmlFor='userType' className={`px-4`}>Available Time</label>
                                        </div>
                                            {/* <div className="mb-3 form-floating  inline-block col-md-6 col-sm-6">
                                                <input className="form-control" type="time" name="availableDate" placeholder="Available Date" maxLength={200} id="availableDate" />
                                                <label htmlFor="availableDate" className="px-4">Available Time</label>
                                            </div>  */}
                                            </>}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Modal Ends */}
                </div>
            </>
        );
    }
}

export default Rescheduling;