import { Row, Col, Tabs, Skeleton } from "antd";
import React from "react";
import {
  CorporateCodeInterface,
  DrivingSchoolInterface,
  ScoreInterface,
  UserInterface,
} from "../../../types";
import { RRServer } from "../../../utils/lib";

import dayjs from "dayjs";
import { match } from "assert";
import TopBar from "../../TopBar";
import { SelectColumnFilter } from "./../../TableComp/Filter";
import TableContainer from "./../../TableComp/TableContainer";

const { TabPane } = Tabs;

interface DashProps {
  schools: DrivingSchoolInterface[];
  corporateCodes: CorporateCodeInterface[];
  corporateCodesCount: number;
  drivingSchoolCodesCount: number;
  studentsCount: number;
  instructorCount: number;
  adminCount: number;
  loading: boolean;

  user: UserInterface | null;
  scores: ScoreInterface[];
}

interface studentStatProps {
  studentId: string;
}

class StudentStatClone extends React.Component<studentStatProps, DashProps> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      schools: [],
      corporateCodes: [],
      corporateCodesCount: 0,
      drivingSchoolCodesCount: 0,
      studentsCount: 0,
      instructorCount: 0,
      adminCount: 0,

      user: null,
      scores: [],
    };
  }

  componentDidMount() {
    const id = this.props.studentId;
    console.log("iddd", id);
    Promise.all([
      RRServer.get({ table: RRServer.Tables.USERS, id }),
      RRServer.list({
        table: RRServer.Tables.SCORES,
        forCurrentUser: false,
        where: [["uid", "==", id]],
      }),
    ]).then((results: any[]) => {
      this.setState({
        user: results[0].data.data,
        scores: results[1].data.data,
      });
    });
  }

  render() {
    const columns = [
      {
        Header: "Date",
        accessor: "addedOn",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Game",
        accessor: "game",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Attempt",
        accessor: "attemptNumber",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Score",
        accessor: "score",
        Filter: SelectColumnFilter,
      },
    ];

    const { user, scores } = this.state;

    //Total score from attempt 1
    var totalScore: number = 0;

    for (var i = 0; i < scores.length; i++) {
      if (scores[i].attemptNumber == 1) {
        totalScore += scores[i].score;
      }
    }

    return (
      <>
        <h5 style={{position:"relative"}}>{ `Student Stat for ${this.state.user?.name} (${this.state.user?.code})`} <span style={{position:"absolute", right:0}}>{`Total Score : ${totalScore}`}</span> </h5>
        <div className="table show">
          <TableContainer columns={columns} data={this.state.scores} />
        </div>
      </>
    );
  }
}

export default StudentStatClone;
