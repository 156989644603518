import React from 'react';
import { Button, Card, Collapse, Divider, Input, Space, Table } from 'antd';
import Icon, { EditFilled, EyeFilled, MinusOutlined, PlusOutlined } from '@ant-design/icons';
// import { Link, withRouter } from 'react-router-dom';
import { callOnServer, getClaims, getLoggedInUserName, RRServer } from '../../../utils/lib';
import { UserInterface } from '../../../types';
import FormComponent from '../../FormComponent/FormComponent';
// import Razorpay from "razorpay";
// import logo from "../../logo.svg";
import { Select } from 'antd';
import TableContainer from '../../TableComp/TableContainer';
import { SelectColumnFilter } from '../../TableComp/Filter';
import TopBar from '../../TopBar';
import UserForm from './subComponents/UserForm';
import AddUserForm from './subComponents/AddUserForm';
import AddInstructorForm from './subComponents/AddInstructorForm';
import DrivingSchoolUserAdd from './subComponents/DrivingSchoolUserAdd';
import UserAdd from './UserAdd';


const { Option } = Select;
const { Panel } = Collapse;

interface DrivingSchoolInterface {
    code: string;
    id: string
    drivingSchoolName: string
    currentCount: string
    pricePerCode: number
    codesRemaining: number
}
interface CorporateInterface {
    code: string;
    id: string
    corporateName: string
    currentCount: string
}
interface CorporateCodesInterface {
    code: string;
    password: string;
}


type schoolHashedType = { [key: string]: DrivingSchoolInterface };
type corporateHashedType = { [key: string]: CorporateInterface };
type corporateCodesHashedType = { [key: string]: CorporateCodesInterface };
interface UserStateProps {
    schools: DrivingSchoolInterface[],
    schoolsHashed: schoolHashedType

    corporates: CorporateInterface[],
    corporatesHashed: corporateHashedType

    selectedSchool: DrivingSchoolInterface | null
    userCode: string
    users: UserInterface[]

    corporateCodes: CorporateCodesInterface[],
    corporateCodesHashed: corporateCodesHashedType


    filterSchoolId: string
    filterUserId: string
    filterMobileNumber: string

    codesToBuy: number,

    loading: boolean,

    isToggle: boolean,
    progress: number,
    userType: string,
    formOneValues: any,
    value: string,
}

// const fields: any = [
//     { type: 'select', name: 'Choose Driving School', label: 'choose driving school', options: ['School One', 'School Two', 'School Three', 'school Four'], isMandatory: false, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
//     { type: 'select', name: 'userType', label: 'User Type', options: ['user', 'admin', 'instructor', 'office admin'], isMandatory: false, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
//     { type: 'text', name: 'name', label: 'Name', isMandatory: false, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
//     { type: 'number', name: 'mobile', label: 'Mobile Number', isMandatory: false, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
//     { type: 'date', name: 'dateOfBirth', label: 'Date of Birth', isMandatory: false, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
//     { type: 'select', name: 'typeOfVehicle', label: 'Type of Vehicle', options: ['Two Wheeler', 'Four Wheeler', 'Heavy', 'Refresher'], isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
//     { type: 'text', name: 'code', label: 'Code', isMandatory: false, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
//     { type: 'password', name: 'password', label: 'Password', isMandatory: false, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
// ]

class UserList extends React.Component<any, UserStateProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            schools: [],
            schoolsHashed: {},
            corporates: [],
            corporatesHashed: {},
            selectedSchool: null,
            userCode: '',
            users: [],
            corporateCodes: [],
            corporateCodesHashed: {},
            filterSchoolId: '',
            filterUserId: '',
            filterMobileNumber: '',
            codesToBuy: 5,
            loading: false,
            isToggle: true,
            progress: 100,
            userType: "user",
            formOneValues: null,
            value: '',
        }
        this.getSchool = this.getSchool.bind(this);
    }

    componentDidMount() {
        //         const companyId=getUse
        this.getData('');

    }

    componentDidUpdate(prevProps: any) {

        if (this.props.drivingSchool !== prevProps.drivingSchool) {
            console.log('Props changed', this.props)
            this.getData(this.props.drivingSchool.id);
        }
    }

    getData = (id: string) => {

        getClaims().then(claims => {
            if (!claims || !!!claims['drivingSchoolId']) { return null }
            const drivingSchoolId = claims ? claims['drivingSchoolId'] : '';

            const userPromise = drivingSchoolId === '*'
                ? RRServer.list({ table: 'users', forCurrentUser: false })
                : RRServer.list({ table: 'users', forCurrentUser: false, where: [['companyId', '==', drivingSchoolId]] });
            const dsPromise = drivingSchoolId === '*'
                ? RRServer.list({ table: 'driving_schools', forCurrentUser: false })
                : RRServer.get({ table: 'driving_schools', id: drivingSchoolId });

            Promise.all([
                // RRServer.list({ table: 'users', forCurrentUser: false, where:[['companyId','==',id]] }),
                userPromise,
                dsPromise,
                RRServer.list({ table: 'corporate_users', forCurrentUser: false }),
                RRServer.list({ table: 'corporate_codes', forCurrentUser: false }),
            ])
                .then((results: any) => {
                    let users = results[0].data.data.sort((a: UserInterface, b: UserInterface) => a.name.localeCompare(b.name));

                    let schools: DrivingSchoolInterface[];
                    let _s: schoolHashedType = {}
                    if (results[1].data.data) {
                        schools = results[1].data.data.length ? results[1].data.data : [results[1].data.data];
                        _s = {};
                        schools.map(school => _s[school.id] = school)
                    } else {
                        schools = [];
                    }

                    let corporates: CorporateInterface[] = results[2].data.data;
                    let _c: corporateHashedType = {};
                    corporates.map(corp => _c[corp.id] = corp)

                    let corporateCodes: CorporateCodesInterface[] = results[3].data.data;
                    let _cc: corporateCodesHashedType = {};
                    corporateCodes.map(corp => _cc[corp.code] = corp)
                    this.setState({ users, schools, schoolsHashed: _s, corporates, corporatesHashed: _c, corporateCodes, corporateCodesHashed: _cc })
                });
        })
    }

    getSchool(schoolId: string) {
        const school = this.state.schools.filter(school => school.id = schoolId);
        return school[0];
    }

    getName = (user: UserInterface) => {
        if (!user) { return 'NU' }
        const { schoolsHashed, corporatesHashed } = this.state;
        if (user.type === 'DRIVING_SCHOOL') {
            const _school = schoolsHashed[user.companyId];
            return (_school && _school.drivingSchoolName);
        } else if (user.type === 'CORPORATE_USER_IOCL') {
            const _corp = corporatesHashed[user.companyId];
            return (_corp && _corp.corporateName);
        }
    }

    getPassword = (user: UserInterface) => {
        if (user.password) {
            return user.password;
        }
        const { corporateCodesHashed } = this.state;
        const cc = corporateCodesHashed[user.code];
        return cc ? cc.password : '-';
    }

    loadScript(src: string) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    createOrder = async () => {
        const { schools, codesToBuy } = this.state;
        const pricePerCode = schools[0].pricePerCode;

        if (!pricePerCode) {
            alert('Price Per Code Not Set');
            return;
        }

        return RRServer.callFn('payment-createOrder', {
            pricePerCode: pricePerCode, codes: codesToBuy
        })
            .then((r: any) => r.data.order)
    }

    displayRazorpay = async () => {
        const { schools, codesToBuy } = this.state;

        const res = await this.loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        this.setState({ loading: true })


        // creating a new order
        const result = await this.createOrder();
        console.log(result);

        if (!result) {
            alert("Server error. Are you online?");
            return;
        }

        // // Getting the order details back
        const { amount, id: order_id, currency } = result;
        const name = getLoggedInUserName();

        const options = {
            key: "rzp_live_bfig0kGIyXdE7J", // Enter the Key ID generated from the Dashboard
            amount: amount.toString(),
            currency: currency,
            name: name,
            description: "Roadrakshak User Codes",
            // image: { logo },
            order_id: order_id,
            handler: async (response: any) => {
                const data = {
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                    code: codesToBuy
                };


                RRServer.add({
                    table: 'payments', data: {
                        razorpayPaymentId: response.razorpay_payment_id,
                        razorpayOrderId: response.razorpay_order_id,
                        amount,
                        codesToBuy,
                        pricePerCode: schools[0].pricePerCode,
                        schoolId: schools[0].id,
                        schoolName: schools[0].drivingSchoolName
                    }
                });
                const codesRemaining = schools[0].codesRemaining || 0;
                RRServer.update({ table: RRServer.Tables.DRIVING_SCHOOLS, id: schools[0].id, codesRemaining: codesRemaining + codesToBuy });
                alert('Payment Successful');
                this.setState({ loading: false })

                return;
                //skipping this for now
                //@todo MUST VERIFY ORDER
                RRServer.callFn('payment-verifyOrder', data)
                    .then((r: any) => {
                        console.log('Success', r);
                        // alert(result.data.msg);
                        if (r.data.s) {

                            RRServer.add({
                                table: 'payments', data: {
                                    razorpayPaymentId: response.razorpay_payment_id,
                                    razorpayOrderId: response.razorpay_order_id,
                                    amount,
                                    codesToBuy,
                                    pricePerCode: schools[0].pricePerCode,
                                    schoolId: schools[0].id,
                                    schoolName: schools[0].drivingSchoolName
                                }
                            });
                            const codesRemaining = schools[0].codesRemaining || 0;
                            RRServer.update({ table: RRServer.Tables.DRIVING_SCHOOLS, id: schools[0].id, codesRemaining: codesRemaining + codesToBuy });
                            alert('Payment Successful');
                            this.setState({ loading: false })

                        }

                    })

            },
            prefill: {
                name: name,

            },
            notes: {

            },
            theme: {
                color: "#61dafb",
            },
        };

        const paymentObject = new (window as any).Razorpay(options);
        paymentObject.open();
    }

    subtractCodes = () => this.setState({ codesToBuy: this.state.codesToBuy == 5 ? 5 : this.state.codesToBuy - 5 })
    addCodes = () => this.setState({ codesToBuy: this.state.codesToBuy + 5 })

    toggleForm = (e: any) => {
        e.preventDefault();

        if (this.state.progress === 1 && (this.state.formOneValues.userType === 'user' || this.state.formOneValues.userType === 'instructor')) {
            this.setState({ progress: 50 })
        } else if (this.state.progress === 100 && (this.state.userType === 'user' || this.state.userType === 'instructor')) {
            this.setState({ isToggle: !this.state.isToggle, progress: 1 });
        } else {
            this.setState({ progress: 1, isToggle: !this.state.isToggle })
        }
    }

    cancelForm = (e: any) => {
        e.preventDefault();
        this.setState({ isToggle: !this.state.isToggle, progress: 100 });
    }

    handleValues = (values: any) => {
        console.log('values from parent', values)
        this.setState({ formOneValues: values, value: values.userType })
    }

    handleFormOneValues = (values: any) => {
        console.log('formONeValues', values)
        if(values.userType === 'user' || values.userType === 'instructor'){
            this.setState({ formOneValues: { ...values }, progress: 50 })
        } else {
            this.setState({ formOneValues: { ...values },  isToggle: !this.state.isToggle, progress: 100  })
        }
    }

  
    render() {

        const { users, schools, filterSchoolId, filterUserId, filterMobileNumber, codesToBuy } = this.state;
        // let { url } = this.props.match;
        const currentSchool = this.props.drivingSchool || {};


        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || '';

        let dataSource= users.map(user => {    if(user.fcmToken){
            return {...user,appDownload: 'YES', key: user.id}
        } else {
            return {...user, appDownload: 'NO', key: user.id}
        }})
    
        const columns = [
            {
                Header: 'Schools',
                accessor: 'companyId',
                // Filter: SelectColumnFilter,
                // disableSortBy: true,
                disableFilters: true,
                Cell: (val:any)=>{
                 const sklData: any =  this.state.schools.filter((skl:any)=>{
                    return skl?.id === val?.value
                })
                return  sklData[0]?sklData[0].drivingSchoolName : null
                }
            },
            {
                Header: 'Name',
                accessor: 'name',
                Filter: SelectColumnFilter,
                // disableSortBy: true
            },
            {
                Header: 'Code',
                accessor: 'code',
            },
            {
                Header: 'Password',
                accessor: 'password',
            },
            {
                Header: 'Mobile',
                accessor: 'mobile',
            },
            {
                Header: 'App Download',
                accessor: 'appDownload',
                Filter: SelectColumnFilter,
                // Cell: (user: UserInterface) => {
                //     return !!user.fcmToken ? 'Yes' : 'No';
                // },
            },
            {
                Header: 'Type',
                accessor: 'type',
                Filter: SelectColumnFilter,
            },
            // {
            //     Header: 'Action',
            //     accessor: 'action',
            //     disableSortBy: true,
            //     disableFilters: true,
            //     Cell: () => {
            //         return <div style={{ display: 'flex', justifyContent: "space-around", cursor: "not-allowed" }}>
            //             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
            //                 <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
            //                 <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
            //             </svg>
            //             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
            //                 <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
            //                 <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
            //             </svg>
            //         </div>
            //     },
            // },
        ]

        if (filterSchoolId) {
            dataSource = dataSource.filter((d:any) => d.companyId === filterSchoolId)
            console.log(dataSource)
        }
        if (filterUserId) {
            dataSource = dataSource.filter(d => d.id === filterUserId)
        }
        if (filterMobileNumber) {
            dataSource = dataSource.filter(d => d.id === filterMobileNumber)
        }



        return (
            <>
                <TopBar title="Users" />
   
                <div className="contentContainer">
                    <div className='contentTop'>
                        <h5>{this.state.isToggle ? 'Users List' : 'Add User'}</h5>
                        {claims.drivingSchoolId === "*" ?
                    
                    <Select
                        showSearch
                        style={{ width: 200, marginLeft:"50px" }}
                        placeholder="Filter School"
                        optionFilterProp="children"
                        onChange={(id) => this.setState({ filterSchoolId: id + "", })}
                        // onFocus={onFocus}
                        // onBlur={onBlur}
                        // onSearch={onSearch}
                        filterOption={(input:any, option:any) => {
                            return option!.children ? option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                        }
                        }
                    >
                        {schools.map(school => <Option key={school.id} value={school.id}>{school.drivingSchoolName}</Option>)}

                    </Select>
                
                : ""}
                        <div className='tableBtn' style={{ marginLeft: "auto" }}>
                            {this.state.isToggle && <button className='btn btn-primary' onClick={(e) => this.toggleForm(e)}>ADD DRIVING SCHOOL USER</button>}
                            {!this.state.isToggle && <button className='btn btn-primary' onClick={(e) => this.cancelForm(e)}>{this.state.isToggle ? 'ADD DRIVING SCHOOL USER' : 'CANCEL'}</button>}
                        </div>
                    </div>
                   
                    <div className={this.state.isToggle ? `table show` : `table hide`}>
                        {this.state.progress === 100 && <TableContainer columns={columns} data={dataSource} />}
                    </div>
                    <div className={this.state.isToggle ? `form hide` : `form show`}>
                            <UserAdd/>
                        {/* {this.state.progress === 1 && <UserForm handleValues={this.handleFormOneValues}  schools={this.state.schools}/>} */}
                        {/* {this.state.progress === 50 && this.state.formOneValues.userType === 'user' && <AddUserForm/>} */}
                        {/* {this.state.progress === 50 && this.state.formOneValues.userType === 'user' && <DrivingSchoolUserAdd/>} */}
                        {/* {this.state.progress === 50 && this.state.formOneValues.userType === 'instructor' && <AddInstructorForm/>} */}
                    </div>
                </div>
            </>

        );
    }
}
// export default (UserAdd);
export default UserList;