import React from 'react';
import firebase from '../../../utils/Firebase';
import { Divider, Select, Space, Table } from 'antd';
import { EditFilled, EyeFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import TableContainer from '../../TableComp/TableContainer';
import FormComponent from '../../FormComponent/FormComponent';
import { callOnServer, RRServer } from '../../../utils/lib';
import TopBar from '../../TopBar';
import { SelectColumnFilter } from '../../TableComp/Filter';
import DrivingSchoolAdd from './DrivingSchoolAdd';
import DrivingAdd from './DrivingAdd';

const { Option } = Select;



interface DrivingSchoolInterface {
    code: string;
    id: string
    drivingSchoolName: string
    currentCount: string
}

interface UserStateProps {
    schools: DrivingSchoolInterface[]
    selectedSchool: DrivingSchoolInterface | null
    userCode: string
    filterSchoolId: string,
    isToggle: boolean,
    isSearch: boolean,
    claims:any
}

class DrivingSchools extends React.Component<any, UserStateProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            schools: [],
            selectedSchool: null,
            userCode: '',
            filterSchoolId: '',
            isToggle: true,
            isSearch: false,
            claims: null

        }
        this.getSchool = this.getSchool.bind(this);
    }

    componentDidMount() {

        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        this.setState({claims:JSON.parse(claimsString)})
        claims.drivingSchoolId = claims.drivingSchoolId || ''

        if (claims.drivingSchoolId !== '*' ) {

            RRServer.get({ table: 'driving_schools', id: claims.drivingSchoolId }).then((result: any) => {
                console.log('dataaaa gett',result)
                let schools = [result?.data?.data];
                this.setState({ schools })
            });

        } else if(claims.drivingSchoolId !== '#' && claims.userType === 'CLUSTER') {
            RRServer.get({ table: 'driving_schools', id: claims.clusterID }).then((result: any) => {
                console.log('dataaaa gett',result)
                let schools = [result?.data?.data];
                this.setState({ schools })
            });

        } else if(claims.drivingSchoolId !== '#' && claims.userType === 'ASSOCIATION') {
            RRServer.get({ table: 'driving_schools', id: claims?.associationID }).then((result: any) => {
                console.log('dataaaa gett',result)
                let schools = [result?.data?.data];
                this.setState({ schools })
            });
            
        }
        
        else {
            RRServer.list({ table: 'driving_schools', forCurrentUser: false }).then((result: any) => {
                console.log('dataaaa listt',result)

                let schools = result.data.data;
                this.setState({ schools })
            });
        }
    }

    getSchool(schoolId: string) {
        const school = this.state.schools.filter(school => school.id = schoolId);
        return school[0];
    }


    toggleForm = (e: any) => {
        e.preventDefault();
        this.setState({ isToggle: !this.state.isToggle });
        // console.log(this.state.isToggle);
    }

    handleValues = (values: any) => {
        console.log(values)
    }




    render() {
        const { schools, filterSchoolId } = this.state;
        // let { url } = this.props.match;


        let dataSource = schools.map(school => ({ key: school?.id, ...school }))

        if (filterSchoolId) {
            dataSource = dataSource.filter(d => d?.id === filterSchoolId)
        }

        const columns = [
            {
                Header: 'Name',
                accessor: 'drivingSchoolName',
                Filter: SelectColumnFilter,
                // disableSortBy: true
            },
            {
                Header: 'Code',
                accessor: 'code',
                // disableFilters: true
            },
            {
                Header: 'Current Count',
                accessor: 'currentCount',
            },
            {
                Header: 'Action',
                accessor: "id",
                disableFilters: true,
                Cell: (val:any) => {
                    return <div style={{ display: 'flex', justifyContent: "space-around", cursor:"pointer"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" onClick={()=> window.location.href = `drivingschools/edit/${val.value}`} width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                    </svg>
                </div>
                },
            }
        ]


        return (
            <>
                {/* <main>
                <div>
                    <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Filter School"
                        optionFilterProp="children"
                        onChange={(id) => this.setState({ filterSchoolId: id + "" })}
                        // onFocus={onFocus}
                        // onBlur={onBlur}
                        // onSearch={onSearch}
                        filterOption={(input, option) => {
                            return option!.children ? option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                        }
                        }
                    >
                        {schools.map(school => <Option key={school.id} value={school.id}>{school.drivingSchoolName}</Option>)}

                    </Select>
                </div>
                <Table dataSource={dataSource} columns={columns} />
            </main> */}

                <>
                    <TopBar title="Driving Schools" />

                    <div className='contentContainer'>
                        <div className='contentTop'>
                            <h5>{this.state.isToggle ? 'Driving School List' : 'Add Driving School'}</h5>

                            {this.state.claims?.drivingSchoolId === '*'? <div className='tableBtn' style={{ marginLeft: "auto" }}>
                                {/* <button className='btn btn-primary' onClick={(e) => this.toggleForm(e)}>{this.state.isToggle ? 'ADD DRIVING SCHOOL' : 'CANCEL'}</button> */}
                                <button className='btn btn-primary' onClick={(e) => window.location.href = 'drivingschools/add'}>ADD DRIVING SCHOOL</button>

                            </div>: ""}
                        </div>
                        <div className={`${this.state.isToggle ? 'table show' : 'table hide'}`}>
                           
                            <TableContainer columns={columns} data={dataSource} />
                        </div>
                        <div className={`${this.state.isToggle ? `form hide` : `form show`}`}>
                            {/* <FormComponent fields={fields} toggleForm={(e: any) => this.toggleForm(e)} handleValues={this.handleValues} buttonText='Submit' /> */}
                            {/* <DrivingSchoolAdd/> */}
                            {/* <DrivingAdd/> */}
                        </div>
                    </div>
                </>
            </>
        );
    }
}

// export default withRouter(UserAdd);
export default DrivingSchools;