// ***** Dev ******

import  firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'


// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyDu5tLf40F_Q_ljvP-gjQLL3tqj5OK1Da8",
//   authDomain: "roadrakshak-ds-dev.firebaseapp.com",
//   projectId: "roadrakshak-ds-dev",
//   storageBucket: "roadrakshak-ds-dev.appspot.com",
//   messagingSenderId: "615333576533",
//   appId: "1:615333576533:web:89eedac96d79c5f9f579dc",
//   measurementId: "G-N773YJDM0L"
// };

const firebaseConfig = {
  apiKey: "AIzaSyDu5tLf40F_Q_ljvP-gjQLL3tqj5OK1Da8",
  authDomain: "roadrakshak-ds-dev.firebaseapp.com",
  projectId: "roadrakshak-ds-dev",
  storageBucket: "roadrakshak-ds-dev.appspot.com",
  messagingSenderId: "615333576533",
  appId: "1:615333576533:web:89eedac96d79c5f9f579dc",
  measurementId: "G-N773YJDM0L"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
firebase.auth().languageCode = 'en';
  
export default firebase;


// ****** production ******

// import  firebase from 'firebase/app';
// import 'firebase/auth'
// import 'firebase/functions'
// import 'firebase/storage'

// // Import the functions you need from the SDKs you need
// // import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyCYREHHeZ--O42zAOiSEcKlIZKRO932Am8",
//   authDomain: "ashokleyland-f5e67.firebaseapp.com",
//   projectId: "ashokleyland-f5e67",
//   storageBucket: "ashokleyland-f5e67.appspot.com",
//   messagingSenderId: "1037376803957",
//   appId: "1:1037376803957:web:e557ee4ecafef2c221143c",
//   measurementId: "G-TXD2MNX4BV"
// };

// // Initialize Firebase
// const app =  firebase.initializeApp(firebaseConfig);
// firebase.auth().languageCode = 'en';

// export default firebase;
