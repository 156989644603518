import React from 'react';
import { Divider, Space, Table } from 'antd';
import { EditFilled, EyeFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { RRServer } from '../../../utils/lib';
import TopBar from '../../TopBar';
import FormComponent from '../../FormComponent/FormComponent';
import Listing from '../../Listing/Listing';
import NewsCard from '../../Listing/Card/NewsCard';
import styles from '../../Listing/Listing.module.css';
import NewsAdd from './NewsAdd';


interface NewsInterface {
    id: string
    title: string
    content: string
}

interface UserStateProps {
    news: NewsInterface[],
    isAdding: boolean,
}

// const fields: any = [
//     { type: 'select', name: 'destination', label: 'Destination', options: [{value:"ap", label:"Asian Paints"},{value:"iocl", label:"IOCL"},{value:"ap", label:"Asian Paints"}], isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
//     { type: 'select', name: 'language', label: 'Language', options: ['Tamil', 'Malayalam', 'Telugu', 'Kanadam', 'English'], isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
//     { type: 'text', name: 'title', label: 'Title', isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
//     { type: 'text', name: 'content', label: 'Content', isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
//     { type: 'text', name: 'fileUrl', label: 'File Url', isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
//     { type: 'text', name: 'videoUrl', label: 'video Url', isMandatory: false, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
//     { type: 'date', name: 'publishDate', label: 'PublishDate', isMandatory: true, gridMd: 12, gridSm: 12, validation: (value: any) => !value ? false : true },
// ]

class NewsClone extends React.Component<any, UserStateProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            news: [],
            isAdding: false
        }
    }

    componentDidMount() {

        RRServer.list({ table: 'news_articles', forCurrentUser: false }).then((result: any) => {
            let news = result.data.data;
            this.setState({ news })
        });


    }

    toggleForm = (e: any) => {
        e.preventDefault();
        // console.log(this.state.isToggle);
    }

    handleValues = (values: any) => {
        console.log(values)
    }


    render() {
        const { news } = this.state;
        // let { url } = this.props.match;
        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        const isLoggedIn = localStorage.getItem('claims');
        const isSuperAdmin = claims.drivingSchoolId === "*"? true : false
        const isClusterAdmin = claims.drivingSchoolId === "#" && claims?.userType === "CLUSTER"? true : false
        const isAssociationAdmin = claims.drivingSchoolId === "#" && claims?.userType === "ASSOCIATION"? true : false
        const isSkl =  claims.drivingSchoolId !== "*" && claims.drivingSchoolId !== "#"? true : false;

        const dataSource = news.map(news => ({ key: news.id, ...news }))

        const columns = [
            // {
            //     title: 'ID',
            //     dataIndex: 'id',
            //     key: 'id',
            // },
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
            },

            {
                title: 'Content',
                dataIndex: 'content',
                key: 'content',
            },
            {
                title: 'Destination',
                dataIndex: 'destination',
                key: 'destination',
            },


            {
                title: 'Action',
                key: 'action',
                sorter: true,
                // render: ({ id }: { id: string }) => (
                //     <Space split={<Divider type="vertical" />}>
                //         <Link to={`${url}/edit/${id}`} key='edit-0'>
                //             <EditFilled />
                //         </Link>
                //         <Link to={`${url}/view/${id}`} key='view-1'>
                //             <EyeFilled />
                //         </Link>
                //         {/* <Link to={`${url}/view/${id}`} key='view-1'>
                //             <DeleteFilled />
                //         </Link> */}
                //     </Space>
                // ),
            },
        ];

        return (
            <>
            <TopBar title="News" />
            <div className='contentContainer'>
                <div className='contentTop'>
                    <h5>News List</h5>
                    {!isSkl && <button className='btn btn-primary' onClick={() => this.setState({ isAdding: !this.state.isAdding })}>{!this.state.isAdding ? `Add News` : `cancel`}</button>}
                </div>
                {console.log('newsss',this.state.news)}

                <div className={styles.jobContainer}>

                <div className={styles.containerOne}>

                    <div className={styles.searchBar}>

                        <label htmlFor="search">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#aaaaaa" className="bi bi-search" viewBox="0 0 16 16">
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                        </label>

                        <input type="text"  name="search" id="search" placeholder='Search News' />
                    </div>

                </div>

                <div className={styles.containerTwo}>

                   {isAssociationAdmin && !this.state.isAdding  && this.state?.news.map((news:any, idx:any)=>{
                        if(news.destination === 'dsTN' ) {
                            return <NewsCard key={idx}
                              language={news.lang}
                              destination={news.destination}
                              content={news.content}
                              title={news.title}
                              date={news.date}
                              image={news.fileUrl}
                              video={news.videoUrl}/>
                        } 
                    })}
                      {isSuperAdmin && !this.state.isAdding &&  this.state?.news.map((news:any, idx:any)=>{
                            return <NewsCard key={idx}
                            language={news.lang}
                                 destination={news.destination}
                              content={news.content}
                              title={news.title}
                              date={news.date}
                              image={news.fileUrl}
                              video={news.videoUrl} 
                              />
                    })}
                    {isSkl && !this.state.isAdding &&  this.state?.news.map((news:any, idx:any)=>{
                            if(news.destination === 'dsTN' ) {
                                return <NewsCard key={idx}
                                language={news.lang}
                                destination={news.destination}
                                  content={news.content}
                                  title={news.title}
                                  date={news.date}
                                  image={news.fileUrl}
                                  video={news.videoUrl}/>
                            } 
                    })}
                    
                </div>
            </div>

                {/* {!this.state.news && !this.state.isAdding &&
                    <Listing />
                } */}
                {/* {this.state.isAdding && <FormComponent fields={fields} toggleForm={(e: any) => this.toggleForm(e)} handleValues={this.handleValues} buttonText='ADD' />} */}
                {this.state.isAdding && <NewsAdd/>}

            </div>

            
        </>
        );
    }
}

// export default withRouter(UserAdd);
export default NewsClone;